import { IAnalyticsService } from "../interfaces/IAnalyticsService"

export class InMemoryAnalyticsService implements IAnalyticsService {
  private events: Array<{ name: string; data: any }> = []

  send(name: string, data = {}) {
    this.events.push({ name, data })
  }

  getEvents() {
    return [...this.events]
  }
}
