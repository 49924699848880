import { GameEntity } from "../entities/GameEntity"
import { IGridsRepository } from "../interfaces/IGridsRepository"

export class InMemoryGridsRepository implements IGridsRepository {
  private grids: Array<GameEntity> = []

  storeGrid(user: GameEntity) {
    this.grids.push(user)
  }

  async findClassicalGames(): Promise<GameEntity[]> {
    return this.grids.filter(grid => grid.selectable)
  }

  async findChallenges(): Promise<GameEntity[]> {
    return this.grids.filter(grid => !grid.selectable)
  }
}
