export const Next = "REDUX_TUTORIAL_NEXT"
export const Reset = "REDUX_TUTORIAL_RESET"

export interface NextAction {
  type: typeof Next
}
export interface ResetAction {
  type: typeof Reset
}

export type TutorialActionTypes = NextAction | ResetAction
