import * as types from "./types"
import { PlayService } from "../../services/PlayService"
import { GameEntity, MatrixEntity } from "../../entities/GameEntity"
import { HistoryEntity } from "../../entities/HistoryEntity"

interface PlayState {
  games: Map<GameEntity["id"], GameEntity>
  challenges: Map<GameEntity["id"], GameEntity>
  playing: boolean
  actual: GameEntity | null
  base: GameEntity | null
  undo: Array<GameEntity>
  draftMode: boolean
  isChallenge: boolean
  adjacent: Array<string>
  paused: boolean
  win: boolean
  selected: string | null
  levelChoosing: number
  beforeLevelChoosing: number
  startDate: number | null
  errorNumber: number
  history: Map<HistoryEntity["id"], HistoryEntity>
}

const landing: GameEntity = {
  id: "5x5.1",
  size: "5x5",
  locked: false,
  selectable: true,
  matrix: [
    [
      { id: "0:0", number: 2, bottom: "gt" },
      { id: "0:1", number: 1 },
      { id: "0:2", number: 4, bottom: "lt" },
      { id: "0:3", number: 3 },
      { id: "0:4", number: 5 },
    ],
    [
      { id: "1:0", number: 1 },
      { id: "1:1", number: 4, bottom: "gt" },
      { id: "1:2", number: 5 },
      { id: "1:3", number: 2 },
      { id: "1:4", number: 3 },
    ],
    [
      { id: "2:0", number: 5, bottom: "gt" },
      { id: "2:1", number: 3 },
      { id: "2:2", number: 2, default: true },
      { id: "2:3", number: 4, right: "gt" },
      { id: "2:4", number: 1 },
    ],
    [
      { id: "3:0", number: 3, right: "gt" },
      { id: "3:1", number: 2, right: "gt" },
      { id: "3:2", number: 1 },
      { id: "3:3", number: 5 },
      { id: "3:4", number: 4 },
    ],
    [
      { id: "4:0", number: 4, default: true },
      { id: "4:1", number: 5, right: "gt" },
      { id: "4:2", number: 3 },
      { id: "4:3", number: 1, right: "lt" },
      { id: "4:4", number: 2 },
    ],
  ],
}

const tutorial: GameEntity = {
  id: "4x4.1",
  size: "4x4",
  selectable: true,
  locked: false,
  matrix: [
    [
      { id: "0:0", number: 3 },
      { id: "0:1", number: 4 },
      { id: "0:2", number: 2, default: true },
      { id: "0:3", number: 1 },
    ],
    [
      { id: "1:0", number: 1, bottom: "lt" },
      { id: "1:1", number: 2 },
      { id: "1:2", number: 3 },
      { id: "1:3", number: 4 },
    ],
    [
      { id: "2:0", number: 2 },
      { id: "2:1", number: 1, default: true },
      { id: "2:2", number: 4, right: "gt" },
      { id: "2:3", number: 3, bottom: "gt" },
    ],
    [
      { id: "3:0", number: 4, right: "gt" },
      { id: "3:1", number: 3 },
      { id: "3:2", number: 1, right: "lt" },
      { id: "3:3", number: 2 },
    ],
  ],
}

const initialState: PlayState = {
  games: new Map().set(landing.id, landing).set(tutorial.id, tutorial),
  challenges: new Map(),
  isChallenge: false,
  undo: [],
  adjacent: [],
  draftMode: false,
  startDate: null,
  levelChoosing: 0,
  beforeLevelChoosing: -1,
  playing: false,
  actual: null,
  base: null,
  paused: false,
  win: false,
  errorNumber: 0,
  selected: null,
  history: new Map(),
}

export function playReducer(
  state = initialState,
  action: types.PlayActionTypes
): PlayState {
  if (action.type === types.StoreHistory) {
    return {
      ...state,
      history: new Map(action.payload.history.map(h => [h.id, h])),
    }
  }

  if (action.type === types.StoreGames) {
    return {
      ...state,
      games: new Map(action.payload.games.map(game => [game.id, game])),
    }
  }

  if (action.type === types.StoreChallenges) {
    return {
      ...state,
      challenges: new Map(
        action.payload.challenges.map(game => [game.id, game])
      ),
    }
  }

  if (action.type === types.Reset) {
    return {
      ...initialState,
      games: state.games,
      challenges: state.challenges,
      history: state.history,
      levelChoosing: state.levelChoosing,
      beforeLevelChoosing: state.beforeLevelChoosing,
    }
  }

  if (action.type === types.Undo) {
    const [, before, ...rest] = state.undo

    if (!before) return state

    return {
      ...state,
      actual: before,
      undo: [before, ...(rest || [])] || [],
    }
  }

  if (action.type === types.NextChoose) {
    const next = state.levelChoosing + 1

    if (next >= 6) return state

    return {
      ...state,
      levelChoosing: state.levelChoosing + 1,
      beforeLevelChoosing: state.levelChoosing,
    }
  }

  if (action.type === types.SelectCel) {
    if (state.selected === action.payload.id)
      return {
        ...state,
        selected: null,
        adjacent: [],
      }

    const [row, col] = action.payload.id.split(":")

    return {
      ...state,
      selected: action.payload.id,
      adjacent: [
        `${Number(row) - 1}:${col}`,
        `${row}:${Number(col) + 1}`,
        `${Number(row) + 1}:${col}`,
        `${row}:${Number(col) - 1}`,
      ],
    }
  }

  if (action.type === types.PreviousChoose) {
    return {
      ...state,
      levelChoosing: state.levelChoosing - 1,
      beforeLevelChoosing: state.levelChoosing,
    }
  }

  if (action.type === types.SelectGame) {
    const getId = () => {
      if (action.payload.id) return action.payload.id
      const level = action.payload.level
      const challenges = Array.from(state.challenges.values()).filter(
        ({ size }) => {
          return size === level
        }
      )
      const index = Math.floor(Math.random() * (challenges.length - 1))
      return challenges[index].id
    }

    const isChallenge = action.payload.isChallenge || false
    const id = getId()

    const game = new PlayService()
    const bucket = isChallenge ? state.challenges : state.games
    const base = bucket.get(id)
    const startDate = Date.now()

    return {
      ...initialState,

      games: state.games,
      challenges: state.challenges,
      levelChoosing: state.levelChoosing,
      beforeLevelChoosing: state.beforeLevelChoosing,
      history: state.history,

      isChallenge,
      playing: true,
      base,
      startDate,
      actual: {
        ...base,
        matrix: game.create(base.matrix),
      },
      undo: [
        {
          ...base,
          matrix: game.create(base.matrix),
        },
      ],
    }
  }

  if (action.type === types.ChangeCel) {
    const { value } = action.payload
    const id = state.selected

    if (!id) return state

    const game = new PlayService()
    const matrix: MatrixEntity = state.actual.matrix.map(row =>
      row.map(cel => ({
        ...cel,
        number: cel.id === id ? value : cel.number,
      }))
    )

    const actual = game.parse(state.base.matrix, matrix)
    const winner = game.isWinner(state.base.matrix, actual)

    if (winner) {
      const history = {
        id: state.base.id,
        succeed: true,
        duration: Date.now() - state.startDate,
      }

      state.history.set(history.id, history)
    }

    return {
      ...state,
      playing: true,
      actual: {
        ...state.actual,
        matrix: actual,
      },
      undo: [
        {
          ...state.actual,
          matrix: actual,
        },
        ...state.undo,
      ],
      win: winner,
    }
  }

  if (action.type === types.ChangeDraft) {
    const { value } = action.payload
    const id = state.selected

    if (!id) return state

    const matrix: MatrixEntity = state.actual.matrix.map(row =>
      row.map(cel => ({
        ...cel,
        drafts:
          cel.id === id
            ? cel.drafts.map((number, index) => {
                if (index !== value - 1) return number
                if (number) return null
                return value
              })
            : cel.drafts,
      }))
    )

    return {
      ...state,
      actual: {
        ...state.actual,
        matrix,
      },
      undo: [
        {
          ...state.actual,
          matrix,
        },
        ...state.undo,
      ],
    }
  }

  if (action.type === types.ToggleDraftMode) {
    return {
      ...state,
      draftMode: !state.draftMode,
    }
  }

  return state
}
