import { MatrixEntity } from "../entities/GameEntity"

export class PlayService {
  constructor() {}

  create(base: MatrixEntity): MatrixEntity {
    return base.map(row =>
      row.map(col => {
        return {
          id: col.id,
          number: col.default ? col.number : null,
          default: !!col.default,
          drafts: Array.from({ length: 9 }).map(() => null),
          ...(col.right ? { right: col.right } : {}),
          ...(col.bottom ? { bottom: col.bottom } : {}),
        }
      })
    )
  }

  isWinner(base: MatrixEntity, actual: MatrixEntity): boolean {
    const valueOf = (matrix: MatrixEntity) =>
      matrix.map(row => row.map(cel => cel.number))

    return valueOf(base).toString() === valueOf(actual).toString()
  }

  parse(base: MatrixEntity, actual: MatrixEntity): MatrixEntity {
    return actual.map((row, rowIndex) =>
      row.map((col, colIndex) => {
        if (col.number > 0 && base[rowIndex][colIndex].number !== col.number)
          return {
            ...col,
            error: true,
          }

        return {
          ...col,
          error: false,
        }
      })
    )
  }
}
