module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Futoshiki","short_name":"Futoshiki","start_url":"/app/?app=true","theme_color":"#f4f5f7","background_color":"#f4f5f7","display":"standalone","icons":[{"src":"/manifest/16x16.png","sizes":"16x16","type":"image/png"},{"src":"/manifest/64x64.png","sizes":"64x64","type":"image/png"},{"src":"/manifest/32x32.png","sizes":"32x32","type":"image/png"},{"src":"/manifest/24x24.png","sizes":"24x24","type":"image/png"},{"src":"/manifest/192x192.png","type":"image/png","sizes":"192x192"},{"src":"/manifest/512x512.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.futoshiki.app"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JJN01SCL9B"]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b09ee9673f1844a0bba0bc593d4e8d8e@o495044.ingest.sentry.io/5567141","autoSessionTracking":true,"sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude-analytics/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"3c6c2bf5dfca5a6bfe6c07775270f607","head":false,"eventTypes":{"outboundLinkClick":"OUTBOUND_LINK_CLICK","pageView":"PAGE_VIEW"},"amplitudeConfig":{"saveEvents":true,"includeUtm":true,"includeReferrer":true},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
