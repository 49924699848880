import { GameEntity } from "../../entities/GameEntity"
import { HistoryEntity } from "../../entities/HistoryEntity"

export const SelectGame = "REDUX_PLAY_SELECT_GAME"
export const StoreGames = "REDUX_PLAY_STORE_GAMES"
export const StoreChallenges = "REDUX_PLAY_STORE_CHALLENGES"
export const ChangeCel = "REDUX_PLAY_CHANGE_CEL"
export const StoreHistory = "REDUX_PLAY_STORE_HISTORY"
export const FetchEnd = "REDUX_PLAY_FETCH_END"
export const Fetching = "REDUX_PLAY_FETCHING"
export const Reset = "REDUX_PLAY_RESET"
export const NextChoose = "REDUX_PLAY_NEXT_CHOOSE"
export const PreviousChoose = "REDUX_PLAY_PREVIOUS_CHOOSE"
export const ChangeDraft = "REDUX_PLAY_CHANGE_DRAFT"
export const ToggleDraftMode = "REDUX_PLAY_TOGGLE_DRAFT_MODE"
export const SelectCel = "REDUX_PLAY_SELECT_CEL"
export const Undo = "REDUX_PLAY_UNDO"

export interface UndoAction {
  type: typeof Undo
}

export interface ToggleDraftModeAction {
  type: typeof ToggleDraftMode
}

export interface StoreGamesAction {
  type: typeof StoreGames,
  payload: { games: GameEntity[] }
}

export interface StoreChallengesAction {
  type: typeof StoreChallenges,
  payload: { challenges: GameEntity[] }
}

export interface ResetAction {
  type: typeof Reset
}

export interface NextChooseAction {
  type: typeof NextChoose
}

export interface PreviousChooseAction {
  type: typeof PreviousChoose
}

export interface StoreHistoryAction {
  type: typeof StoreHistory
  payload: { history: HistoryEntity[] }
}

export interface ChangeDraftAction {
  type: typeof ChangeDraft
  payload: { value: number }
}

export interface ChangeCelAction {
  type: typeof ChangeCel
  payload: { value: number | null }
}

export interface SelectGameAction {
  type: typeof SelectGame
  payload: { id: string, isChallenge?: boolean, level?: string }
}

export interface SelectCelAction {
  type: typeof SelectCel
  payload: { id: string }
}

export interface FetchingAction {
  type: typeof Fetching
}

export interface FetchEndAction {
  type: typeof FetchEnd
}

export type PlayActionTypes =
  | SelectGameAction
  | NextChooseAction
  | UndoAction
  | SelectCelAction
  | ToggleDraftModeAction
  | ChangeDraftAction
  | PreviousChooseAction
  | ResetAction
  | StoreGamesAction
  | StoreChallengesAction
  | ChangeCelAction
  | StoreHistoryAction
  | FetchingAction
  | FetchEndAction
