export const Next = "REDUX_DEMO_NEXT"
export const Reset = "REDUX_DEMO_RESET"

export interface NextAction {
  type: typeof Next
}
export interface ResetAction {
  type: typeof Reset
}

export type DemoActionTypes = NextAction | ResetAction
