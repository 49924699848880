import {
  UserEntity,
  UserWithoutIdEntity,
  UserWithoutPasswordEntity,
} from "../entities/UserEntity"

import {
  IAuthRepository,
  AuthenticateReturnType,
  CheckResetPasswordCodeReturnType,
  UpdatePasswordCodeReturnType,
} from "../interfaces/IAuthRepository"

export const users: { [key: string]: UserEntity } = {
  base: {
    email: "kevin@gmail.com",
    id: "1",
    avatar: "1",
    username: "lol",
    password: "root",
  },
}

export class InMemoryAuthRepository implements IAuthRepository {
  private resetCodes: Array<{ email: UserEntity["email"]; code: string }> = []
  private users: Array<UserEntity> = [users.base]

  async authenticate(params: {
    email: string
    password: string
  }): Promise<AuthenticateReturnType> {
    const emailExists = this.users.find(user => user.email === params.email)
    const passwordExists = this.users.find(
      user => user.email === params.email && user.password === params.password
    )

    if (emailExists && passwordExists)
      return { authenticated: true, user: emailExists }
    if (!emailExists) return { authenticated: false, error: "USER_NOT_FOUND" }
    return { authenticated: false, error: "BAD_PASSWORD" }
  }

  async checkIfUserExistsByEmail(email: string): Promise<{ exists: boolean }> {
    const exists = this.users.find(user => user.email === email)

    return { exists: !!exists }
  }

  async checkIfUserExistsByUsername(
    username: string
  ): Promise<{ exists: boolean }> {
    const exists = this.users.find(user => user.username === username)

    return { exists: !!exists }
  }

  async sendResetPasswordCode(
    email: string,
    custom?: string
  ): Promise<{ succeed: true } | { succeed: false; errors: "ERROR_SERVER"[] }> {
    this.resetCodes.push({
      email,
      code: custom || String(Math.random() * 100000),
    })

    return {
      succeed: true,
    }
  }

  async register(
    user: UserWithoutIdEntity
  ): Promise<
    | {
        user: UserWithoutPasswordEntity
        authenticated: true
      }
    | {
        authenticated: false
        errors: string[]
      }
  > {
    const id = String(this.users.length)
    const entity = { ...user, id }

    this.users.push(entity)

    const { password, ...rest } = entity

    return { user: rest, authenticated: true }
  }

  updatePasswordErrorReturnValue: UpdatePasswordCodeReturnType | false = false

  setUpdatePasswordErrorReturn(value: UpdatePasswordCodeReturnType) {
    this.updatePasswordErrorReturnValue = value
  }

  async updatePassword(params: {
    email: string
    password: string
    code: string
  }): Promise<UpdatePasswordCodeReturnType> {
    if (this.updatePasswordErrorReturnValue)
      return this.updatePasswordErrorReturnValue

    this.users.map(user => ({
      ...user,
      password: user.email === params.email ? params.password : user.password,
    }))

    return { succeed: true }
  }

  checkResetPasswordCodeReturnErrorValue:
    | CheckResetPasswordCodeReturnType
    | false = false

  setCheckResetPasswordCodeReturn(value: CheckResetPasswordCodeReturnType) {
    this.checkResetPasswordCodeReturnErrorValue = value
  }

  async checkResetPasswordCode(
    code: string
  ): Promise<CheckResetPasswordCodeReturnType> {
    if (this.checkResetPasswordCodeReturnErrorValue)
      return this.checkResetPasswordCodeReturnErrorValue

    const reset = this.resetCodes.find(props => props.code === code)

    if (reset) return { succeed: true, email: reset.email }

    return { succeed: false, error: "USER_NOT_FOUND" }
  }
}
