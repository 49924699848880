import cursorAudio from "../../assets/audio/cursor.mp3"
import beginAudio from "../../assets/audio/begin.mp3"
import completeAudio from "../../assets/audio/complete.mp3"
import nextAudio from "../../assets/audio/next.mp3"
import selectAudio from "../../assets/audio/select.mp3"
import successAudio from "../../assets/audio/success.mp3"
import closeAudio from "../../assets/audio/close.mp3"
import hmmAudio from "../../assets/audio/hmm.mp3"
import sneerAudio from "../../assets/audio/sneer.mp3"

export const Init = "REDUX_SOUNDS_INIT"

export const sounds = () => ({
  cursor: new Audio(cursorAudio),
  begin: new Audio(beginAudio),
  complete: new Audio(completeAudio),
  next: new Audio(nextAudio),
  select: new Audio(selectAudio),
  success: new Audio(successAudio),
  close: new Audio(closeAudio),
  hmm: new Audio(hmmAudio),
  sneer: new Audio(sneerAudio),
})

export interface InitAction {
  type: typeof Init
}

export type SoundsType = ReturnType<typeof sounds>

export type SoundsActionTypes = InitAction
