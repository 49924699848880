import React from "react"
import { Provider } from "react-redux"
import { StaticInitialization } from "./src/components/StaticInitialization/StaticInitialization"
import { SnackBar } from "./src/components/Snack/SnackBar"

import { init } from "./src/redux/store"
import { FirebaseAuthRepository } from "./src/repositories/FirebaseAuthRepository"
import { FirebaseEmailRepository } from "./src/repositories/FirebaseEmailRepository"
import { GatsbyLocationService } from "./src/services/GatsbyLocationService"
import { AmplitudeAnalyticsService } from "./src/services/AmplitudeAnalyticsService"
import { FirebaseGridsRepository } from "./src/repositories/FirebaseGridsRepository"
import { Firebase } from "./src/services/firebase"

export default ({ element }) => {
  const firebase = new Firebase()
  const { store } = init({}, [
    { key: "AuthRepository", value: new FirebaseAuthRepository(firebase) },
    {
      key: "GridsRepository",
      value: new FirebaseGridsRepository(firebase),
    },
    {
      key: "EmailRepository",
      value: new FirebaseEmailRepository(firebase),
    },
    {
      key: "AnalyticsService",
      value: new AmplitudeAnalyticsService(),
    },
    {
      key: "LocationService",
      value: new GatsbyLocationService(),
    },
  ])

  return (
    <Provider store={store}>
      <StaticInitialization>
        <>
          {element}
          <SnackBar />
        </>
      </StaticInitialization>
    </Provider>
  )
}
