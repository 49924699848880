import * as types from "./types"
import { IAuthRepository } from "../../interfaces/IAuthRepository"
import { IGridsRepository } from "../../interfaces/IGridsRepository"
import { InMemoryAuthRepository } from "../../repositories/InMemoryAuthRepository"
import { InMemoryGridsRepository } from "../../repositories/InMemoryGridsRepository"
import { ILocationService } from "../../interfaces/ILocationService"
import { InMemoryLocationService } from "../../services/InMemoryLocationService"
import { IEmailRepository } from "../../interfaces/IEmailRepository"
import { InMemoryEmailRepository } from "../../repositories/InMemoryEmailRepository"
import { IAnalyticsService } from "../../interfaces/IAnalyticsService"
import { InMemoryAnalyticsService } from "../../services/InMemoryAnalyticsService"

type DiState = {
  AuthRepository: IAuthRepository
  EmailRepository: IEmailRepository
  GridsRepository: IGridsRepository
  LocationService: ILocationService
  AnalyticsService: IAnalyticsService
}

const initialState: DiState = {
  AuthRepository: new InMemoryAuthRepository(),
  EmailRepository: new InMemoryEmailRepository(),
  GridsRepository: new InMemoryGridsRepository(),
  LocationService: new InMemoryLocationService(),
  AnalyticsService: new InMemoryAnalyticsService(),
}

export function diReducer(
  state = initialState,
  action: types.DiActionTypes
): DiState {
  if (action.type === types.register) {
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    }
  }

  return state
}
