import { GameEntity, GameEntityFromDatabase } from "../entities/GameEntity"
import { IGridsRepository } from "../interfaces/IGridsRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import { flatten } from "ramda"

export class FirebaseGridsRepository
  extends FirebaseUtils
  implements IGridsRepository {
  constructor(private firebase: Firebase) {
    super()
  }

  async findClassicalGames(): Promise<GameEntity[]> {
    const sizes = ["4x4", "5x5", "6x6", "7x7", "8x8", "9x9"]

    const games = await Promise.all(
      sizes.map(size =>
        this.firebase
          .database()
          .collection("grids")
          .where("size", "==", size)
          .where("selectable", "==", true)
          .orderBy("order")
          .get()
          .then(snapshots => {
            const game = this.mapQuerySnapshot<GameEntityFromDatabase>(
              snapshots
            )
            return game.map(game => ({
              ...game,
              matrix: JSON.parse(game.matrix),
            })) as GameEntity[]
          })
      )
    )

    return flatten(games) as GameEntity[]
  }

  async findChallenges(): Promise<GameEntity[]> {
    const sizes = ["4x4", "5x5", "6x6", "7x7", "8x8", "9x9"]
    const from = Math.floor(Math.random() * 60) + 40

    const games = await Promise.all(
      sizes.map(size =>
        this.firebase
          .database()
          .collection("grids")
          .where("size", "==", size)
          .where("selectable", "==", false)
          .where("order", ">", from)
          .orderBy("order")
          .limit(10)
          .get()
          .then(snapshots => {
            const game = this.mapQuerySnapshot<GameEntityFromDatabase>(
              snapshots
            )
            return game.map(game => ({
              ...game,
              matrix: JSON.parse(game.matrix),
            })) as GameEntity[]
          })
      )
    )

    return flatten(games) as GameEntity[]
  }
}
