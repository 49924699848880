export default {
  "choose.mode.title": "Select a game mode",
  "choose.mode.challenge": "Daily challenges",
  "choose.mode.grids": "Classical",
  "choose.mode.stats": "Stats",

  "modal/locked/title": "This feature will arrive soon",
  "modal/locked/submit": "Send",
  "modal/locked/description":
    "Want to be notified when the feature goes live? Follow us on social media or fill in your email address, we'll keep you posted.",

  "choose.tutorial": "Tutorial",
  "choose.buttons.next": "Next",
  "choose.buttons.previous": "Previous",

  "index.try.title": "Want to try ?",
  "index.try.description": "Play whenever you want, it's free !",
  "index.try.play": "Play",

  "challenges.choose.title": "Challenge difficulty",

  "footer.rights": "All rights reserved",

  "index.header.play": "Play",

  "index.hero.title.first": `Futoshiki`,
  "index.hero.title.last": `the new Sudoku`,
  "index.hero.subtitle": "News",
  "index.hero.description": `Futoshiki is the modern version of Sudoku, come and discover
  this new puzzle game through +200 grids going from the
  easy to very difficult level.`,

  "index.stats.title": "Futoshiki in several figures",
  "index.stats.description":
    "Relax and take on the challenges offered with an infinity of Futoshiki grids.",

  "index.stats.grid.first.title": `Challenges to overcome`,
  "index.stats.grid.first.value": `+200`,

  "index.stats.grid.middle.title": `Grids to discover`,
  "index.stats.grid.middle.value": `500K`,

  "index.stats.grid.last.title": `Zen attitude`,
  "index.stats.grid.last.value": `100%`,

  "index.content.title": "What are the rules and principle of futoshiki?",
  "index.content.description":
    "A different sudoku mixing logic and memorization",

  "index.content.content.left": `
  <p>
    In sudoku, every row, every column and every
    blocks must contain only numbers
    different. In futoshiki it is the same, at
    the exception that there is no block, but only
    rows and columns.
  </p>
  <p>
    Finally, what's new in futoshiki is
    the appearance of arrow-shaped symbols. The arrow
    indicates that the number in the adjacent box is a plus
    small or larger than the other box, quite simply.
  </p>
`,
  "index.content.content.right": `
  <p>
    To sum up, futoshiki is an alternate version of
    sudoku, which was created in 2006. We believe that the
    futoshiki deserves to gain popularity, that's why
    that we have created this site to allow you to
    discover and have fun playing futoshiki.
  </p>
  `,

  "levels.very.easy": `Very easy`,
  "levels.easy": `Easy`,
  "levels.medium": `Normal`,
  "levels.hard": `Hard`,
  "levels.very.hard": `Very hard`,
  "levels.pro": `Professionnal`,
  "levels.end": `End`,

  "play.success.title": "It is a success !",
  "play.success.description":
    "You have completed this grid, congratulations! You still have plenty of other grids to discover!",
  "play.success.submit": "Continue",

  "play.controls.notes": "Notes",
  "play.controls.undo": "Undo",
  "play.controls.erase": "Erase",

  "tutorial.success.title": "You have completed the tutorial !",
  "tutorial.success.description":
    "You are now ready to take on all the challenges that futoshiki has in store for you! Press continue to choose to pick one up!",
  "tutorial.success.submit": "Continue",

  "tutorial.steps.1": "Oh ! A new student!",
  "tutorial.steps.2": "Mmh .. Let me introduce myself, I am master Futoshiki!",
  "tutorial.steps.3":
    "I'm about to teach you the basics of this noble puzzle ...",
  "tutorial.steps.4": "Are you ready ? Let's go!",
  "tutorial.steps.5":
    "First of all, the goal is to fill each box with a number.",
  "tutorial.steps.6":
    "However, the number should not appear more than once per row and column !",
  "tutorial.steps.7": "Let's rectify that ...",
  "tutorial.steps.8": "That's much better !",
  "tutorial.steps.9": "Now, let's see what these arrows are used for.",
  "tutorial.steps.10":
    "The right arrow shows that the number in the next box is lower.",
  "tutorial.steps.11": "So, the figure below is therefore also lower",
  "tutorial.steps.12": "And so on...",
  "tutorial.steps.13":
    "Thanks to them, we can therefore guess which are the correct numbers.",
  "tutorial.steps.14": "The only possible continuation is thus this one.",
  "tutorial.steps.15": "That's all there was to know about futoshiki !",
  "tutorial.steps.16":
    "I will teach more courses on more advanced strategies later.",
  "tutorial.steps.17": "In the meantime, I wish you a good game!",
  "tutorial.steps.18": " ",

  // Not translated
  "signup.next.button": "Next",
  "signup.next.previous": "Previous",
}
