import delay from "delay"
import { ThunkAction } from "redux-thunk"
import { actions } from "../actions"
import { RootState } from "../store"
import * as types from "./types"

export const storeNext = (): types.DemoActionTypes => ({
  type: types.Next,
})

export const reset = (): types.DemoActionTypes => ({
  type: types.Reset,
})

const getAction = (label, params) => {
  if (label === "select") return () => actions.play.selectCel({ id: params })
  if (label === "erase") return () => actions.play.changeCel({ value: null })
  if (label === "change")
    return () => actions.play.changeCel({ value: Number(params) })
  if (label === "sound") return () => actions.sounds.play(params)
}

export const next = (): ThunkAction<any, RootState, any, any> => async (
  dispatcher,
  getState
) => {
  dispatcher(storeNext())

  const { demo } = getState()

  const step = demo.steps[demo.current]

  if (!step || !step.actions) return

  for (const action of step.actions) {
    const [label, parameter, timeout] = action

    const actionable = getAction(label, parameter) as Function

    if (timeout) {
      await delay(Number(timeout))
      dispatcher(actionable())
    } else {
      dispatcher(actionable())
    }
  }
}
