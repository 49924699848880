import firebase from "firebase/app"

export class Firebase {
  constructor() {
    if (firebase.apps.length === 0)
      firebase.initializeApp({
        apiKey: "AIzaSyCp5GsHzIBwMOw0HDi0_N9_fsnGU6td9NM",
        authDomain: "futoshiki-647c8.firebaseapp.com",
        projectId: "futoshiki-647c8",
        storageBucket: "futoshiki-647c8.appspot.com",
        messagingSenderId: "520170155026",
        appId: "1:520170155026:web:a2e696fd1ba956c7e73ccb",
        measurementId: "G-JJN01SCL9B",
      })

    try {
      firebase.firestore().enablePersistence()
    } catch (e) {}
  }

  database() {
    return firebase.firestore()
  }

  getInstance() {
    return firebase
  }

  auth() {
    return firebase.auth()
  }

  analytics() {
    return firebase.analytics()
  }
}
