import { UserWithoutPasswordEntity } from "../../entities/UserEntity"
import * as types from "./types"
import { lensPath, set, view } from "ramda"
import { UpdatePasswordCodeReturnErrorType } from "../../interfaces/IAuthRepository"

export const FlowsSigninEmailStateCodes = {
  BAD_FORMED: "BAD_FORMED",
  ALREADY_USED: "ALREADY_USED",
  NOT_EXISTS: "NOT_EXISTS",
  NOTHING: "NOTHING",
  GOOD: "GOOD",
}

export type FlowsSigninEmailStateCodesTypes = keyof typeof FlowsSigninEmailStateCodes

export const FlowsForgotEmailStateCodes = {
  NOT_EXISTS: "NOT_EXISTS",
  NOTHING: "NOTHING",
  GOOD: "GOOD",
}

export type FlowsForgotEmailStateCodesTypes = keyof typeof FlowsForgotEmailStateCodes

export const FlowsSignupUsernameStateCodes = {
  ALREADY_USED: "ALREADY_USED",
  EMPTY: "EMPTY",
  NOTHING: "NOTHING",
  GOOD: "GOOD",
}
export type FlowsSignupUsernameStateCodesTypes = keyof typeof FlowsSignupUsernameStateCodes

export const FlowsSigninPasswordStateCodes = {
  NOTHING: "NOTHING",
  NOT_THE_SAME: "NOT_THE_SAME",
  BAD_PASSWORD: "BAD_PASSWORD",
  EMPTY: "EMPTY",
  WEAK: "WEAK",
  GOOD: "GOOD",
}

export type FlowsSigninPasswordStateCodesTypes = keyof typeof FlowsSigninPasswordStateCodes

export const FlowsForgotValidationPasswordStateCodes = {
  NOTHING: "NOTHING",
  EMPTY: "EMPTY",
  WEAK: "WEAK",
  GOOD: "GOOD",
}

export type FlowsForgotValidationPasswordStateCodesTypes = keyof typeof FlowsForgotValidationPasswordStateCodes

interface AuthState {
  user: UserWithoutPasswordEntity | null
  flows: {
    signin: {
      steps: {
        current: number
        number: number
      }
      process: {
        fetching: boolean
        error: "EMAIL_DOES_NOT_EXIST" | "BAD_PASSWORD" | null
        success: boolean
        forgotSuccess: boolean
      }
      form: {
        email: {
          focus: boolean
          value: string
          state: {
            code: FlowsSigninEmailStateCodesTypes
          }
        }
        password: {
          hidden: boolean
          focus: boolean
          value: string
          state: {
            code: FlowsSigninPasswordStateCodesTypes
          }
        }
      }
    }
    forgot: {
      steps: {
        current: number
        number: number
      }
      process: {
        succeed: boolean
        fetching: boolean
        error: "EMAIL_DOES_NOT_EXIST" | null
      }
      form: {
        email: {
          focus: boolean
          value: string
          state: {
            code: FlowsForgotEmailStateCodesTypes
          }
        }
      }
    }
    forgotValidation: {
      steps: {
        current: number
        number: number
      }
      process: {
        succeed: boolean
        fetching: boolean
        error: UpdatePasswordCodeReturnErrorType | null
      }
      form: {
        password: {
          value: string
          hidden: boolean
          focus: boolean
          weak: 0 | 1 | 2 | 3
          state: {
            code: FlowsForgotValidationPasswordStateCodesTypes
          }
        }
      }
    }
    signup: {
      steps: {
        current: number
        number: number
      }
      process: {
        fetching: boolean
        error: "EMAIL_DOES_NOT_EXIST" | "BAD_PASSWORD" | null
      }
      form: {
        email: {
          value: string
          focus: boolean
          state: {
            code: FlowsSigninEmailStateCodesTypes
          }
        }
        avatar: {
          value: string | null
        }
        username: {
          focus: boolean
          value: string
          state: {
            code: FlowsSignupUsernameStateCodesTypes
          }
        }
        password: {
          value: string
          hidden: boolean
          focus: boolean
          weak: 0 | 1 | 2 | 3
          state: {
            code: FlowsSigninPasswordStateCodesTypes
          }
        }
      }
    }
  }
  authenticated: boolean
}

const initialState: AuthState = {
  user: null,
  authenticated: false,
  flows: {
    forgot: {
      steps: {
        current: 0,
        number: 1,
      },
      process: {
        succeed: false,
        fetching: false,
        error: null,
      },
      form: {
        email: {
          focus: false,
          value: "",
          state: {
            code: FlowsForgotEmailStateCodes[
              "NOTHING"
            ] as FlowsForgotEmailStateCodesTypes,
          },
        },
      },
    },
    forgotValidation: {
      steps: {
        current: 0,
        number: 1,
      },
      process: {
        succeed: false,
        fetching: false,
        error: null,
      },
      form: {
        password: {
          value: "",
          focus: false,
          hidden: true,
          weak: 0,
          state: {
            code: FlowsForgotValidationPasswordStateCodes[
              "NOTHING"
            ] as FlowsForgotValidationPasswordStateCodesTypes,
          },
        },
      },
    },
    signin: {
      steps: {
        current: 0,
        number: 2,
      },
      process: {
        fetching: false,
        error: null,
        success: false,
        forgotSuccess: false,
      },
      form: {
        email: {
          focus: false,
          value: "",
          state: {
            code: FlowsSigninEmailStateCodes[
              "NOTHING"
            ] as FlowsSigninEmailStateCodesTypes,
          },
        },
        password: {
          hidden: true,
          focus: false,
          value: "",
          state: {
            code: FlowsSigninPasswordStateCodes[
              "NOTHING"
            ] as FlowsSigninPasswordStateCodesTypes,
          },
        },
      },
    },
    signup: {
      steps: {
        number: 4,
        current: 0,
      },
      process: {
        fetching: false,
        error: null,
      },
      form: {
        email: {
          focus: false,
          value: "",
          state: {
            code: FlowsSigninEmailStateCodes[
              "NOTHING"
            ] as FlowsSigninEmailStateCodesTypes,
          },
        },
        avatar: {
          value: "men/white",
        },
        username: {
          focus: false,
          value: "",
          state: {
            code: FlowsSignupUsernameStateCodes[
              "NOTHING"
            ] as FlowsSignupUsernameStateCodesTypes,
          },
        },
        password: {
          value: "",
          focus: false,
          hidden: true,
          weak: 0,
          state: {
            code: FlowsSigninPasswordStateCodes[
              "NOTHING"
            ] as FlowsSigninPasswordStateCodesTypes,
          },
        },
      },
    },
  },
}

export function authReducer(
  state = initialState,
  action: types.AuthActionTypes
): AuthState {
  if (action.type === types.authenticate) {
    return {
      ...state,
      authenticated: true,
      user: action.payload.user,
    }
  }

  /**
   * ANCHOR Signin
   */
  if (action.type === types.FlowsSigninFormEmailUpdateType) {
    const path = lensPath(["flows", "signin", "form", "email", "value"])
    const code = lensPath(["flows", "signin", "form", "email", "state", "code"])

    return set(code, "NOTHING", set(path, action.payload.email, state))
  }

  if (action.type === types.FlowsSigninFormEmailCheckType) {
    const path = lensPath(["flows", "signin", "form", "email", "state", "code"])
    const email = state.flows.signin.form.email.value

    if (!/[a-zA-Z.-_0-9]+@[a-zA-Z.-_0-9]+\.[a-z]+/gi.test(email))
      return set(path, "BAD_FORMED", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsSigninProcessSucceedUpdateType) {
    const path = lensPath(["flows", "signin", "process", "success"])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsSigninProcessForgotPasswordSucceedUpdateType) {
    const path = lensPath(["flows", "signin", "process", "forgotSuccess"])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsSigninFormPasswordUpdateType) {
    const path = lensPath(["flows", "signin", "form", "password", "value"])
    const code = lensPath([
      "flows",
      "signin",
      "form",
      "password",
      "state",
      "code",
    ])

    return set(code, "NOTHING", set(path, action.payload.password, state))
  }

  if (action.type === types.FlowsResetType) {
    return {
      ...state,
      flows: { ...initialState.flows },
    }
  }

  if (action.type === types.FlowsSigninProcessFetchingType) {
    const path = lensPath(["flows", "signin", "process", "fetching"])

    return set(path, true, state)
  }

  if (action.type === types.FlowsSigninProcessFetchEndType) {
    const path = lensPath(["flows", "signin", "process", "fetching"])

    return set(path, false, state)
  }

  if (action.type === types.FlowsSigninFormPasswordCheckType) {
    const path = lensPath([
      "flows",
      "signin",
      "form",
      "password",
      "state",
      "code",
    ])
    const password = state.flows.signin.form.password.value

    if (password === null || password.length === 0)
      return set(path, "EMPTY", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsSigninProcessErrorSetType) {
    const path = lensPath(["flows", "signin", "process", "error"])

    return set(path, action.payload.error, state)
  }

  if (action.type === types.FlowsSigninFormEmailUpdateFocusType) {
    const path = lensPath(["flows", "signin", "form", "email", "focus"])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsSigninFormPasswordUpdateStateType) {
    const path = lensPath([
      "flows",
      "signin",
      "form",
      "password",
      "state",
      "code",
    ])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsSigninFormEmailUpdateStateType) {
    const path = lensPath(["flows", "signin", "form", "email", "state", "code"])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsSigninFormPasswordUpdateFocusType) {
    const path = lensPath(["flows", "signin", "form", "password", "focus"])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsSigninFormPasswordHiddenToggleType) {
    const path = lensPath(["flows", "signin", "form", "password", "hidden"])

    return set(path, !state.flows.signin.form.password.hidden, state)
  }

  if (action.type === types.FlowsSigninStepsNextType) {
    const path = lensPath(["flows", "signin", "steps", "current"])
    const next = (view(path, state) as number) + 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsSigninStepsPreviousType) {
    const path = lensPath(["flows", "signin", "steps", "current"])
    const current = state.flows.signin.steps.current
    const next = current === 0 ? 0 : current - 1

    return set(path, next, state)
  }

  /**
   * ANCHOR Forgot
   */
  if (action.type === types.FlowsForgotFormEmailUpdateType) {
    const path = lensPath(["flows", "forgot", "form", "email", "value"])
    const code = lensPath(["flows", "forgot", "form", "email", "state", "code"])

    return set(code, "NOTHING", set(path, action.payload.email, state))
  }

  if (action.type === types.FlowsForgotProcessSuccessUpdateType) {
    const path = lensPath(["flows", "forgot", "process", "succeed"])

    return set(path, true, state)
  }

  if (action.type === types.FlowsForgotFormEmailCheckType) {
    const path = lensPath(["flows", "forgot", "form", "email", "state", "code"])
    const email = state.flows.forgot.form.email.value

    if (!/[a-zA-Z.-_0-9]+@[a-zA-Z.-_0-9]+\.[a-z]+/gi.test(email))
      return set(path, "BAD_FORMED", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsForgotProcessFetchingType) {
    const path = lensPath(["flows", "forgot", "process", "fetching"])

    return set(path, true, state)
  }

  if (action.type === types.FlowsForgotProcessFetchEndType) {
    const path = lensPath(["flows", "forgot", "process", "fetching"])

    return set(path, false, state)
  }

  if (action.type === types.FlowsForgotProcessErrorSetType) {
    const path = lensPath(["flows", "forgot", "process", "error"])
    const error = action.payload.error

    return set(path, error, state)
  }

  if (action.type === types.FlowsForgotFormEmailUpdateFocusType) {
    const path = lensPath(["flows", "forgot", "form", "email", "focus"])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsForgotFormEmailUpdateStateType) {
    const path = lensPath(["flows", "forgot", "form", "email", "state", "code"])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsForgotStepsNextType) {
    const path = lensPath(["flows", "forgot", "steps", "current"])
    const next = (view(path, state) as number) + 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsForgotStepsPreviousType) {
    const path = lensPath(["flows", "forgot", "steps", "current"])
    const current = state.flows.forgot.steps.current
    const next = current === 0 ? 0 : current - 1

    return set(path, next, state)
  }

  /**
   * ANCHOR ForgotValidation
   */

  if (action.type === types.FlowsForgotValidationProcessSuccessUpdateType) {
    const path = lensPath(["flows", "forgotValidation", "process", "succeed"])

    return set(path, true, state)
  }

  if (action.type === types.FlowsForgotValidationProcessFetchingType) {
    const path = lensPath(["flows", "forgotValidation", "process", "fetching"])

    return set(path, true, state)
  }

  if (action.type === types.FlowsForgotValidationProcessFetchEndType) {
    const path = lensPath(["flows", "forgotValidation", "process", "fetching"])

    return set(path, false, state)
  }

  if (action.type === types.FlowsForgotValidationFormPasswordCheckType) {
    const path = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "state",
      "code",
    ])

    const weak = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "weak",
    ])

    const password = state.flows.forgotValidation.form.password.value

    if (password === null || password.length === 0)
      return set(weak, 1, set(path, "EMPTY", state))

    if (state.flows.forgotValidation.form.password.weak <= 1)
      return set(path, "WEAK", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsForgotValidationFormPasswordUpdateStateType) {
    const path = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "state",
      "code",
    ])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsForgotValidationStepsNextType) {
    const path = lensPath(["flows", "forgotValidation", "steps", "current"])
    const next = (view(path, state) as number) + 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsForgotValidationStepsPreviousType) {
    const path = lensPath(["flows", "forgotValidation", "steps", "current"])
    const current = state.flows.forgotValidation.steps.current
    const next = current === 0 ? 0 : current - 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsForgotValidationFormPasswordUpdateType) {
    const password = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "value",
    ])
    const weak = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "weak",
    ])

    const code = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "state",
      "code",
    ])

    const value = action.payload.password

    const getLevel = (password: string) => {
      if (password.length === 0) return 0
      if (
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password) &&
        password.length >= 8
      )
        return 3

      if (password.length >= 8) return 2
      return 1
    }

    return set(
      code,
      "NOTHING",
      set(weak, getLevel(value), set(password, action.payload.password, state))
    )
  }

  if (action.type === types.FlowsForgotValidationFormPasswordUpdateFocusType) {
    const path = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "focus",
    ])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsForgotValidationFormPasswordHiddenToggleType) {
    const path = lensPath([
      "flows",
      "forgotValidation",
      "form",
      "password",
      "hidden",
    ])

    return set(path, !state.flows.forgotValidation.form.password.hidden, state)
  }

  if (action.type === types.FlowsForgotValidationProcessSetErrorType) {
    const path = lensPath(["flows", "forgotValidation", "process", "error"])

    return set(path, action.payload.error, state)
  }

  /**
   * ANCHOR Signup
   */

  if (action.type === types.FlowsSignupFormAvatarUpdateType) {
    const path = lensPath(["flows", "signup", "form", "avatar", "value"])

    return set(path, action.payload.id, state)
  }

  if (action.type === types.FlowsSignupFormEmailUpdateType) {
    const path = lensPath(["flows", "signup", "form", "email", "value"])
    const code = lensPath(["flows", "signup", "form", "email", "state", "code"])

    return set(code, "NOTHING", set(path, action.payload.email, state))
  }

  if (action.type === types.FlowsSignupFormEmailUpdateFocusType) {
    const path = lensPath(["flows", "signup", "form", "email", "focus"])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsSignupFormUsernameUpdateFocusType) {
    const path = lensPath(["flows", "signup", "form", "username", "focus"])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsSignupFormUsernameUpdateType) {
    const path = lensPath(["flows", "signup", "form", "username", "value"])
    const code = lensPath([
      "flows",
      "signup",
      "form",
      "username",
      "state",
      "code",
    ])

    return set(
      code,
      FlowsSignupUsernameStateCodes["NOTHING"],
      set(path, action.payload.username, state)
    )
  }

  if (action.type === types.FlowsSignupFormUsernameCheckType) {
    const path = lensPath([
      "flows",
      "signup",
      "form",
      "username",
      "state",
      "code",
    ])
    const username = state.flows.signup.form.username.value

    if (!username || username.length === 0)
      return set(path, FlowsSignupUsernameStateCodes["EMPTY"], state)

    return set(path, FlowsSignupUsernameStateCodes["GOOD"], state)
  }

  if (action.type === types.FlowsSignupFormUsernameUpdateStateType) {
    const path = lensPath([
      "flows",
      "signup",
      "form",
      "username",
      "state",
      "code",
    ])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsSignupFormEmailCheckType) {
    const path = lensPath(["flows", "signup", "form", "email", "state", "code"])
    const email = state.flows.signup.form.email.value

    if (!/[a-zA-Z.-_0-9]+@[a-zA-Z.-_0-9]+\.[a-z]+/gi.test(email))
      return set(path, "BAD_FORMED", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsSignupFormEmailUpdateStateType) {
    const path = lensPath(["flows", "signup", "form", "email", "state", "code"])

    return set(path, action.payload.state, state)
  }

  if (action.type === types.FlowsSignupFormPasswordUpdateType) {
    const password = lensPath(["flows", "signup", "form", "password", "value"])
    const weak = lensPath(["flows", "signup", "form", "password", "weak"])

    const value = action.payload.password

    const getLevel = (password: string) => {
      if (password.length === 0) return 0
      if (
        /[A-Z]/.test(password) &&
        /[0-9]/.test(password) &&
        password.length >= 8
      )
        return 3

      if (password.length >= 8) return 2
      return 1
    }

    return set(
      weak,
      getLevel(value),
      set(password, action.payload.password, state)
    )
  }

  if (action.type === types.FlowsSignupFormPasswordUpdateFocusType) {
    const path = lensPath(["flows", "signup", "form", "password", "focus"])

    return set(path, action.payload.focus, state)
  }

  if (action.type === types.FlowsSignupFormPasswordHiddenToggleType) {
    const path = lensPath(["flows", "signup", "form", "password", "hidden"])

    return set(path, !state.flows.signup.form.password.hidden, state)
  }

  if (action.type === types.FlowsSignupProcessFetchingType) {
    const path = lensPath(["flows", "signup", "process", "fetching"])

    return set(path, true, state)
  }

  if (action.type === types.FlowsSignupProcessFetchEndType) {
    const path = lensPath(["flows", "signup", "process", "fetching"])

    return set(path, false, state)
  }

  if (action.type === types.FlowsSignupFormPasswordCheckType) {
    const path = lensPath([
      "flows",
      "signup",
      "form",
      "password",
      "state",
      "code",
    ])
    const weak = lensPath(["flows", "signup", "form", "password", "weak"])

    const password = state.flows.signup.form.password.value

    if (password === null || password.length === 0)
      return set(weak, 1, set(path, "EMPTY", state))

    if (state.flows.signup.form.password.weak <= 1)
      return set(path, "WEAK", state)

    return set(path, "GOOD", state)
  }

  if (action.type === types.FlowsSignupProcessErrorsSetType) {
    const path = lensPath(["flows", "signup", "process", "error"])
    const error = action.payload.error

    return set(path, error, state)
  }

  if (action.type === types.FlowsSignupStepsNextType) {
    const path = lensPath(["flows", "signup", "steps", "current"])
    const next = (view(path, state) as number) + 1

    return set(path, next, state)
  }

  if (action.type === types.FlowsSignupStepsPreviousType) {
    const path = lensPath(["flows", "signup", "steps", "current"])
    const current = state.flows.signup.steps.current
    const next = current === 0 ? 0 : current - 1

    return set(path, next, state)
  }

  if (action.type === types.logout) {
    return { ...initialState }
  }

  return state
}
