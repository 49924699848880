import { SendResetPasswordCodeReturnErrorType } from "../../../interfaces/IAuthRepository"
import { FlowsForgotEmailStateCodesTypes } from "../reducers"

export const FlowsForgotFormEmailUpdateType =
  "REDUX_AUTH_FLOWS_FORGOT_FORM_EMAIL_UPDATE"
export interface FlowsForgotFormEmailUpdateAction {
  type: typeof FlowsForgotFormEmailUpdateType
  payload: { email: string }
}

export const FlowsForgotFormEmailCheckType =
  "REDUX_AUTH_FLOWS_FORGOT_FORM_EMAIL_CHECK"
export interface FlowsForgotFormEmailCheckAction {
  type: typeof FlowsForgotFormEmailCheckType
}

export const FlowsForgotProcessFetchingType =
  "REDUX_AUTH_FLOWS_FORGOT_PROCESS_FETCHING"
export interface FlowsForgotProcessFetchingAction {
  type: typeof FlowsForgotProcessFetchingType
}

export const FlowsForgotProcessFetchEndType =
  "REDUX_AUTH_FLOWS_FORGOT_PROCESS_FETCH_END"
export interface FlowsForgotProcessFetchEndAction {
  type: typeof FlowsForgotProcessFetchEndType
}

export const FlowsForgotProcessErrorSetType =
  "REDUX_AUTH_FLOWS_FORGOT_PROCESS_ERRORS_SET"
export interface FlowsForgotProcessErrorSetAction {
  payload: { error: SendResetPasswordCodeReturnErrorType }
  type: typeof FlowsForgotProcessErrorSetType
}

export const FlowsForgotProcessSuccessUpdateType =
  "REDUX_AUTH_FLOWS_FORGOT_PROCESS_SUCCEED_UPDATE"
export interface FlowsForgotProcessSuccessUpdateAction {
  payload: { state: boolean }
  type: typeof FlowsForgotProcessSuccessUpdateType
}

export const FlowsForgotFormEmailUpdateStateType =
  "REDUX_AUTH_FLOW_FORGOT_FORM_EMAIL_UPDATE_STATE"
export interface FlowsForgotFormEmailUpdateStateAction {
  type: typeof FlowsForgotFormEmailUpdateStateType
  payload: {
    state: FlowsForgotEmailStateCodesTypes
  }
}

export const FlowsForgotFormEmailUpdateFocusType =
  "REDUX_AUTH_FLOW_FORGOT_FORM_EMAIL_FOCUS_UPDATE"
export interface FlowsForgotFormEmailUpdateFocusAction {
  type: typeof FlowsForgotFormEmailUpdateFocusType
  payload: { focus: boolean }
}

export const FlowsForgotStepsNextType = "REDUX_AUTH_FLOW_FORGOT_STEPS_NEXT"
export interface FlowsForgotStepsNextAction {
  type: typeof FlowsForgotStepsNextType
}

export const FlowsForgotStepsPreviousType =
  "REDUX_AUTH_FLOW_FORGOT_STEPS_PREVIOUS"
export interface FlowsForgotStepsPreviousAction {
  type: typeof FlowsForgotStepsPreviousType
}

export type AuthForgotActionTypes =
  | FlowsForgotProcessSuccessUpdateAction
  | FlowsForgotFormEmailUpdateAction
  | FlowsForgotFormEmailCheckAction
  | FlowsForgotProcessFetchingAction
  | FlowsForgotProcessFetchEndAction
  | FlowsForgotProcessErrorSetAction
  | FlowsForgotFormEmailUpdateStateAction
  | FlowsForgotFormEmailUpdateFocusAction
  | FlowsForgotStepsNextAction
  | FlowsForgotStepsPreviousAction
