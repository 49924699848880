import * as types from "./types"

interface SoundsState {
  audios: types.SoundsType | null
}

const initialState: SoundsState = {
  audios: null,
}

export function soundsReducer(
  state = initialState,
  action: types.SoundsActionTypes
): SoundsState {
  if (action.type === types.Init) {
    return {
      ...state,
      audios: types.sounds(),
    }
  }

  return state
}
