import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"
import {
  AuthenticateReturnType,
  CheckResetPasswordCodeReturnType,
  IAuthRepository,
  UpdatePasswordCodeReturnType,
} from "../interfaces/IAuthRepository"
import { UserEntity } from "../entities/UserEntity"

export class FirebaseAuthRepository
  extends FirebaseUtils
  implements IAuthRepository {
  constructor(private firebase: Firebase) {
    super()
  }

  async authenticate(params: {
    email: string
    password: string
  }): Promise<AuthenticateReturnType> {
    try {
      const firebase = this.firebase.getInstance()

      const response = await firebase
        .auth()
        .signInWithEmailAndPassword(params.email, params.password)

      return {
        authenticated: true,
        user: await this.getUserByEmail(params.email),
      }
    } catch (err) {
      return { authenticated: false, error: err.code }
    }
  }

  async getUserByEmail(email: string): Promise<any> {
    const response = await this.firebase
      .database()
      .collection("users")
      .where("email", "==", email)
      .get()

    return response
  }

  async checkIfUserExistsByEmail(email: string): Promise<{ exists: boolean }> {
    const response = await this.firebase
      .database()
      .collection("users")
      .where("email", "==", email)
      .get()

    return { exists: !response.empty }
  }

  async sendResetPasswordCode(
    email: string
  ): Promise<{ succeed: true } | { succeed: false; errors: "ERROR_SERVER"[] }> {
    return this.firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return { succeed: true }
      })
  }

  async checkIfUserExistsByUsername(
    username: string
  ): Promise<{ exists: boolean }> {
    return this.firebase
      .database()
      .collection("users")
      .where("username", "==", username)
      .get()
      .then(snapshot => ({
        exists: !snapshot.empty,
      }))
  }

  async register(
    user: Pick<UserEntity, "username" | "email" | "avatar" | "password">
  ): Promise<
    | {
        user: Pick<UserEntity, "id" | "username" | "email" | "avatar">
        authenticated: true
      }
    | { authenticated: false; errors: string[] }
  > {
    const response = await this.firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password)

    if (!response.user?.uid) return { errors: [], authenticated: false }

    await this.firebase
      .database()
      .collection("users")
      .doc(response.user.uid)
      .set(user)

    return {
      authenticated: true,
      user: {
        ...user,
        id: response.user.uid,
      },
    }
  }

  async updatePassword(params: {
    email: string
    password: string
    code: string
  }): Promise<UpdatePasswordCodeReturnType> {
    try {
      await this.firebase
        .auth()
        .confirmPasswordReset(params.code, params.password)

      return { succeed: true }
    } catch (err) {
      return { succeed: false, error: err.code }
    }
  }

  async checkResetPasswordCode(
    code: string
  ): Promise<CheckResetPasswordCodeReturnType> {
    try {
      const email = await this.firebase.auth().verifyPasswordResetCode(code)
      return { succeed: true, email }
    } catch (err) {
      return { succeed: false, error: err.code }
    }
  }
}
