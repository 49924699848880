import * as types from "./types"

interface TutorialState {
  steps: Array<{ text: string; actions?: Array<Array<string>> }>
  current: number
  end: boolean
}

const initialState: TutorialState = {
  steps: [
    { text: "tutorial.steps.1" },
    { text: "tutorial.steps.2", actions: [["sound", "hmm"]] },
    { text: "tutorial.steps.3" },
    { text: "tutorial.steps.4", actions: [["sound", "sneer"]] },
    {
      text: "tutorial.steps.5",
      actions: [
        ["select", "0:0"],
        ["change", "3", "200"],
        ["select", "0:1", "200"],
        ["change", "4", "200"],
        ["select", "0:3", "200"],
        ["change", "1", "200"],
        ["select", "0:3", "200"],
      ],
    },
    {
      text: "tutorial.steps.6",
      actions: [
        ["select", "0:0"],
        ["change", "4", "100"],
      ],
    },
    {
      text: "tutorial.steps.7",
      actions: [["change", "3"]],
    },
    {
      text: "tutorial.steps.8",
      actions: [
        ["select", "0:0", "100"],
        ["sound", "sneer"],
      ],
    },
    {
      text: "tutorial.steps.9",
      actions: [["select", "2:2", "100"]],
    },
    { text: "tutorial.steps.10" },
    {
      text: "tutorial.steps.11",
      actions: [["select", "2:3", "100"]],
    },
    { text: "tutorial.steps.12", actions: [["select", "3:3", "100"]] },
    { text: "tutorial.steps.13", actions: [["select", "3:2", "100"]] },
    {
      text: "tutorial.steps.14",
      actions: [
        ["select", "2:2", "200"],
        ["change", "4", "200"],
        ["select", "2:3", "200"],
        ["change", "3", "200"],
        ["select", "3:3", "200"],
        ["change", "2", "200"],
        ["select", "3:2", "200"],
        ["change", "1", "200"],
        ["select", "3:2", "200"],
      ],
    },
    { text: "tutorial.steps.15" },
    { text: "tutorial.steps.16" },
    { text: "tutorial.steps.17", actions: [["sound", "sneer"]] },
    { text: "tutorial.steps.18" },
  ],
  current: 0,
  end: false,
}

export function tutorialReducer(
  state = initialState,
  action: types.TutorialActionTypes
): TutorialState {
  if (action.type === types.Next) {
    const current = state.current + 1

    return {
      ...state,
      current: state.current + 1,
      end: current >= state.steps.length - 1,
    }
  }

  if (action.type === types.Reset) {
    return {
      ...initialState,
    }
  }

  return state
}
