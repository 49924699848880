import { ILocationService } from "../interfaces/ILocationService"

export class InMemoryLocationService implements ILocationService {
  private history: Array<{ to: string; state: any }> = []

  navigate(to: string, state = {}) {
    this.history.push({ to, state })
  }

  getHistory() {
    return [...this.history]
  }
}
