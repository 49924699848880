import { CheckResetPasswordCodeErrorType } from "../../../interfaces/IAuthRepository"
import { FlowsForgotValidationPasswordStateCodesTypes } from "../reducers"

export const FlowsForgotValidationProcessFetchingType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_FETCHING"
export interface FlowsForgotValidationProcessFetchingAction {
  type: typeof FlowsForgotValidationProcessFetchingType
}

export const FlowsForgotValidationProcessFetchEndType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_FETCH_END"
export interface FlowsForgotValidationProcessFetchEndAction {
  type: typeof FlowsForgotValidationProcessFetchEndType
}

export const FlowsForgotValidationProcessErrorsSetType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_ERRORS_SET"
export interface FlowsForgotValidationProcessErrorsSetAction {
  payload: { error: CheckResetPasswordCodeErrorType }
  type: typeof FlowsForgotValidationProcessErrorsSetType
}

export const FlowsForgotValidationFormPasswordUpdateStateType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PASSWORD_STATE_UPDATE"
export interface FlowsForgotValidationFormPasswordUpdateStateAction {
  payload: { state: FlowsForgotValidationPasswordStateCodesTypes }
  type: typeof FlowsForgotValidationFormPasswordUpdateStateType
}

export const FlowsForgotValidationProcessSuccessUpdateType =
  "REDUX_AUTH_FLOWS_FORGOT_VALIDATION_PROCESS_SUCCEED_UPDATE"
export interface FlowsForgotValidationProcessSuccessUpdateAction {
  payload: { state: boolean }
  type: typeof FlowsForgotValidationProcessSuccessUpdateType
}

export const FlowsForgotValidationStepsNextType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_STEPS_NEXT"
export interface FlowsForgotValidationStepsNextAction {
  type: typeof FlowsForgotValidationStepsNextType
}

export const FlowsForgotValidationStepsPreviousType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_STEPS_PREVIOUS"
export interface FlowsForgotValidationStepsPreviousAction {
  type: typeof FlowsForgotValidationStepsPreviousType
}

export const FlowsForgotValidationFormPasswordUpdateFocusType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_FORM_PASSWORD_FOCUS_UPDATE"
export interface FlowsForgotValidationFormPasswordUpdateFocusAction {
  type: typeof FlowsForgotValidationFormPasswordUpdateFocusType
  payload: { focus: boolean }
}

export const FlowsForgotValidationFormPasswordUpdateType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_FORM_PASSWORD_UPDATE"
export interface FlowsForgotValidationFormPasswordUpdateAction {
  type: typeof FlowsForgotValidationFormPasswordUpdateType
  payload: { password: string }
}

export const FlowsForgotValidationFormPasswordCheckType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_FORM_PASSWORD_CHECK"
export interface FlowsForgotValidationFormPasswordCheckAction {
  type: typeof FlowsForgotValidationFormPasswordCheckType
}

export const FlowsForgotValidationFormPasswordHiddenToggleType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_FORM_PASSWORD_HIDDEN_TOGGLE"
export interface FlowsForgotValidationFormPasswordHiddenToggleAction {
  type: typeof FlowsForgotValidationFormPasswordHiddenToggleType
}

export const FlowsForgotValidationProcessSetErrorType =
  "REDUX_AUTH_FLOW_FORGOT_VALIDATION_PROCESS_SET_ERROR"
export interface FlowsForgotValidationProcessSetErrorAction {
  type: typeof FlowsForgotValidationProcessSetErrorType
  payload: {
    error: CheckResetPasswordCodeErrorType
  }
}

export type AuthForgotValidationActionTypes =
  | FlowsForgotValidationProcessSetErrorAction
  | FlowsForgotValidationProcessFetchingAction
  | FlowsForgotValidationProcessFetchEndAction
  | FlowsForgotValidationProcessErrorsSetAction
  | FlowsForgotValidationProcessSuccessUpdateAction
  | FlowsForgotValidationStepsNextAction
  | FlowsForgotValidationStepsPreviousAction
  | FlowsForgotValidationFormPasswordUpdateFocusAction
  | FlowsForgotValidationFormPasswordUpdateAction
  | FlowsForgotValidationFormPasswordCheckAction
  | FlowsForgotValidationFormPasswordHiddenToggleAction
  | FlowsForgotValidationFormPasswordUpdateStateAction
