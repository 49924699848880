import { AuthenticationErrors } from "../../../interfaces/IAuthRepository"
import {
  FlowsSigninEmailStateCodesTypes,
  FlowsSignupUsernameStateCodesTypes,
} from "../reducers"

export const FlowsSignupFormUsernameUpdateType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_USERNAME_UPDATE"
export interface FlowsSignupFormUsernameUpdateAction {
  type: typeof FlowsSignupFormUsernameUpdateType
  payload: { username: string }
}

export const FlowsSignupFormUsernameCheckType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_USERNAME_CHECK"
export interface FlowsSignupFormUsernameCheckAction {
  type: typeof FlowsSignupFormUsernameCheckType
}

export const FlowsSignupFormAvatarUpdateType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_AVATAR_UPDATE"
export interface FlowsSignupFormAvatarUpdateAction {
  type: typeof FlowsSignupFormAvatarUpdateType
  payload: { id: string }
}

export const FlowsSignupFormEmailUpdateType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_EMAIL_UPDATE"
export interface FlowsSignupFormEmailUpdateAction {
  type: typeof FlowsSignupFormEmailUpdateType
  payload: { email: string }
}

export const FlowsSignupFormEmailUpdateFocusType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_EMAIL_FOCUS_UPDATE"
export interface FlowsSignupFormEmailUpdateFocusAction {
  type: typeof FlowsSignupFormEmailUpdateFocusType
  payload: { focus: boolean }
}

export const FlowsSignupFormUsernameUpdateFocusType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_USERNAME_FOCUS_UPDATE"
export interface FlowsSignupFormUsernameUpdateFocusAction {
  type: typeof FlowsSignupFormUsernameUpdateFocusType
  payload: { focus: boolean }
}

export const FlowsSignupFormEmailCheckType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_EMAIL_CHECK"
export interface FlowsSignupFormEmailCheckAction {
  type: typeof FlowsSignupFormEmailCheckType
}

export const FlowsSignupFormEmailUpdateStateType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_EMAIL_UPDATE_STATE"
export interface FlowsSignupFormEmailUpdateStateAction {
  type: typeof FlowsSignupFormEmailUpdateStateType
  payload: {
    state: FlowsSigninEmailStateCodesTypes
  }
}

export const FlowsSignupFormUsernameUpdateStateType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_USERNAME_UPDATE_STATE"
export interface FlowsSignupFormUsernameUpdateStateAction {
  type: typeof FlowsSignupFormUsernameUpdateStateType
  payload: {
    state: FlowsSignupUsernameStateCodesTypes
  }
}

export const FlowsSignupFormPasswordUpdateFocusType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_PASSWORD_FOCUS_UPDATE"
export interface FlowsSignupFormPasswordUpdateFocusAction {
  type: typeof FlowsSignupFormPasswordUpdateFocusType
  payload: { focus: boolean }
}

export const FlowsSignupFormPasswordUpdateType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_PASSWORD_UPDATE"
export interface FlowsSignupFormPasswordUpdateAction {
  type: typeof FlowsSignupFormPasswordUpdateType
  payload: { password: string }
}

export const FlowsSignupFormPasswordCheckType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_PASSWORD_CHECK"
export interface FlowsSignupFormPasswordCheckAction {
  type: typeof FlowsSignupFormPasswordCheckType
}

export const FlowsSignupFormPasswordHiddenToggleType =
  "REDUX_AUTH_FLOW_SIGNUP_FORM_PASSWORD_HIDDEN_TOGGLE"
export interface FlowsSignupFormPasswordHiddenToggleAction {
  type: typeof FlowsSignupFormPasswordHiddenToggleType
}

export const FlowsSignupProcessFetchingType =
  "REDUX_AUTH_FLOW_SIGNUP_PROCESS_FETCHING"
export interface FlowsSignupProcessFetchingAction {
  type: typeof FlowsSignupProcessFetchingType
}

export const FlowsSignupProcessFetchEndType =
  "REDUX_AUTH_FLOW_SIGNUP_PROCESS_FETCH_END"
export interface FlowsSignupProcessFetchEndAction {
  type: typeof FlowsSignupProcessFetchEndType
}

export const FlowsSignupProcessErrorsSetType =
  "REDUX_AUTH_FLOW_SIGNUP_PROCESS_ERRORS_SET"
export interface FlowsSignupProcessErrorsSetAction {
  payload: { error: AuthenticationErrors }
  type: typeof FlowsSignupProcessErrorsSetType
}

export const FlowsSignupStepsNextType = "REDUX_AUTH_FLOW_SIGNUP_STEPS_NEXT"
export interface FlowsSignupStepsNextAction {
  type: typeof FlowsSignupStepsNextType
}
export const FlowsSignupStepsPreviousType =
  "REDUX_AUTH_FLOW_SIGNUP_STEPS_PREVIOUS"
export interface FlowsSignupStepsPreviousAction {
  type: typeof FlowsSignupStepsPreviousType
}

export type AuthSignupActionTypes =
  | FlowsSignupStepsPreviousAction
  | FlowsSignupFormPasswordUpdateFocusAction
  | FlowsSignupFormEmailUpdateFocusAction
  | FlowsSignupFormPasswordHiddenToggleAction
  | FlowsSignupFormAvatarUpdateAction
  | FlowsSignupProcessErrorsSetAction
  | FlowsSignupFormPasswordCheckAction
  | FlowsSignupFormPasswordUpdateAction
  | FlowsSignupFormEmailUpdateAction
  | FlowsSignupFormEmailCheckAction
  | FlowsSignupProcessFetchingAction
  | FlowsSignupProcessFetchEndAction
  | FlowsSignupFormEmailUpdateStateAction
  | FlowsSignupStepsNextAction
  | FlowsSignupFormUsernameCheckAction
  | FlowsSignupFormUsernameUpdateFocusAction
  | FlowsSignupFormUsernameUpdateAction
  | FlowsSignupFormUsernameUpdateStateAction
