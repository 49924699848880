import * as auth from "./auth/actions"
import * as tutorial from "./tutorial/actions"
import * as demo from "./demo/actions"
import * as snack from "./snack/actions"
import * as menu from "./menu/actions"
import * as play from "./play/actions"
import * as modal from "./modal/actions"
import * as sounds from "./sounds/actions"
import * as di from "./di/actions"

export const actions = {
  auth,
  tutorial,
  sounds,
  snack,
  play,
  demo,
  menu,
  modal,
  di,
}
