import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { actions } from "../actions"

export const open = () => ({
  type: types.open,
})

export const close = () => ({
  type: types.close,
})

export const authenticate = (
  payload: types.authenticateAction["payload"]
): types.AuthActionTypes => ({
  type: types.authenticate,
  payload,
})

export const edit = (
  payload: types.editAction["payload"]
): types.AuthActionTypes => ({
  type: types.edit,
  payload,
})

export const forgot = (): types.AuthActionTypes => ({
  type: types.forgot,
})

export const logout = (): types.AuthActionTypes => ({
  type: types.logout,
})

export const fetching = (): types.AuthActionTypes => ({
  type: types.fetching,
})

export const fetchEnd = (): types.AuthActionTypes => ({
  type: types.fetchEnd,
})

export const flows = {
  // ANCHOR Global
  reset: (): types.AuthActionTypes => ({
    type: types.FlowsResetType,
  }),
  // ANCHOR Signin
  signin: {
    steps: {
      next: (): types.AuthActionTypes => ({
        type: types.FlowsSigninStepsNextType,
      }),
      previous: (): types.AuthActionTypes => ({
        type: types.FlowsSigninStepsPreviousType,
      }),
      fetchNext: (): ThunkAction<any, RootState, any, any> => async (
        dispatcher,
        getState
      ) => {
        const { auth } = getState()
        const current = auth.flows.signin.steps.current

        if (current === 0) return dispatcher(flows.signin.form.email.next())
        if (current === 1) return dispatcher(flows.signin.submit.execute())
      },
    },
    process: {
      fetching: () => ({ type: types.FlowsSigninProcessFetchingType }),
      fetchEnd: () => ({ type: types.FlowsSigninProcessFetchEndType }),
      success: {
        update: (
          payload: types.FlowsSigninProcessSucceedUpdateAction["payload"]
        ) => ({
          type: types.FlowsSigninProcessSucceedUpdateType,
          payload,
        }),
      },
      forgotPassword: {
        succeed: {
          update: (
            payload: types.FlowsSigninProcessForgotPasswordSucceedUpdateAction["payload"]
          ) => ({
            type: types.FlowsSigninProcessForgotPasswordSucceedUpdateType,
            payload,
          }),
        },
        execute: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          dispatcher(flows.signin.process.fetching())

          const { auth, di } = getState()

          const response = await di.AuthRepository.sendResetPasswordCode(
            auth.flows.signin.form.email.value
          )

          dispatcher(flows.signin.process.fetchEnd())

          if (response.succeed) {
            dispatcher(
              flows.signin.process.forgotPassword.succeed.update({
                state: true,
              })
            )
          } else {
            //@todo Envoyer l'erreur sur Sentry car pas normal
            dispatcher(
              actions.snack.create({
                type: "error",
                message: response.error,
              })
            )
          }
        },
      },
      error: {
        set: (payload: types.FlowsSigninProcessErrorSetAction["payload"]) => ({
          type: types.FlowsSigninProcessErrorSetType,
          payload,
        }),
      },
    },
    form: {
      email: {
        update: (
          payload: types.FlowsSigninFormEmailUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSigninFormEmailUpdateType,
          payload,
        }),
        check: (): types.AuthActionTypes => ({
          type: types.FlowsSigninFormEmailCheckType,
        }),
        state: {
          update: (
            payload: types.FlowsSigninFormEmailUpdateStateAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninFormEmailUpdateStateType,
            payload,
          }),
        },
        focus: {
          update: (
            payload: types.FlowsSigninFormEmailUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninFormEmailUpdateFocusType,
            payload,
          }),
        },
        checkIfAlreadyUsed: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          const { auth, di } = getState()
          const email = auth.flows.signin.form.email.value

          const { exists } = await di.AuthRepository.checkIfUserExistsByEmail(
            email
          )

          if (!exists)
            return dispatcher(
              flows.signin.form.email.state.update({
                state: "NOT_EXISTS",
              })
            )

          return dispatcher(
            flows.signin.form.email.state.update({
              state: "GOOD",
            })
          )
        },
        next: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          dispatcher(flows.signin.process.fetching())
          await dispatcher(flows.signin.form.email.checkIfAlreadyUsed())
          dispatcher(flows.signin.process.fetchEnd())

          const { auth } = getState()

          if (auth.flows.signin.form.email.state.code === "GOOD") {
            dispatcher(flows.signin.steps.next())
          }
        },
      },
      password: {
        update: (
          payload: types.FlowsSigninFormPasswordUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSigninFormPasswordUpdateType,
          payload,
        }),
        check: (): types.AuthActionTypes => ({
          type: types.FlowsSigninFormPasswordCheckType,
        }),
        focus: {
          update: (
            payload: types.FlowsSigninFormPasswordUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninFormPasswordUpdateFocusType,
            payload,
          }),
        },
        state: {
          update: (
            payload: types.FlowsSigninFormPasswordUpdateStateAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSigninFormPasswordUpdateStateType,
            payload,
          }),
        },
        hidden: {
          toggle: (): types.AuthActionTypes => ({
            type: types.FlowsSigninFormPasswordHiddenToggleType,
          }),
        },
      },
    },
    submit: {
      execute: (): ThunkAction<any, RootState, any, any> => async (
        dispatcher,
        getState
      ) => {
        const { auth, di } = getState()

        dispatcher(flows.signin.process.fetching())

        const response = await di.AuthRepository.authenticate({
          email: auth.flows.signin.form.email.value,
          password: auth.flows.signin.form.password.value,
        })

        dispatcher(flows.signin.process.fetchEnd())

        if (response.authenticated) {
          dispatcher(authenticate({ user: response.user }))
          dispatcher(flows.signin.process.success.update({ state: true }))
        } else {
          if (response.error === "BAD_PASSWORD")
            dispatcher(
              flows.signin.form.password.state.update({ state: response.error })
            )
        }
      },
    },
  },
  // ANCHOR Forgot
  forgot: {
    steps: {
      next: (): types.AuthActionTypes => ({
        type: types.FlowsForgotStepsNextType,
      }),
      previous: (): types.AuthActionTypes => ({
        type: types.FlowsForgotStepsPreviousType,
      }),
      fetchNext: (): ThunkAction<any, RootState, any, any> => async (
        dispatcher,
        getState
      ) => {
        const { auth } = getState()
        const current = auth.flows.forgot.steps.current

        if (current === 0) return dispatcher(flows.forgot.submit.execute())
      },
    },
    process: {
      succeed: {
        update: (
          payload: types.FlowsForgotProcessSuccessUpdateAction["payload"]
        ) => ({ type: types.FlowsForgotProcessSuccessUpdateType, payload }),
      },
      fetching: () => ({ type: types.FlowsForgotProcessFetchingType }),
      fetchEnd: () => ({ type: types.FlowsForgotProcessFetchEndType }),
      error: {
        set: (payload: types.FlowsForgotProcessErrorSetAction["payload"]) => ({
          type: types.FlowsForgotProcessErrorSetType,
          payload,
        }),
      },
    },
    form: {
      email: {
        update: (
          payload: types.FlowsForgotFormEmailUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsForgotFormEmailUpdateType,
          payload,
        }),
        check: (): types.AuthActionTypes => ({
          type: types.FlowsForgotFormEmailCheckType,
        }),
        state: {
          update: (
            payload: types.FlowsForgotFormEmailUpdateStateAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsForgotFormEmailUpdateStateType,
            payload,
          }),
        },
        focus: {
          update: (
            payload: types.FlowsForgotFormEmailUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsForgotFormEmailUpdateFocusType,
            payload,
          }),
        },
        checkIfAlreadyUsed: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          const { auth, di } = getState()
          const email = auth.flows.forgot.form.email.value

          const { exists } = await di.AuthRepository.checkIfUserExistsByEmail(
            email
          )

          if (!exists)
            return dispatcher(
              flows.forgot.form.email.state.update({
                state: "NOT_EXISTS",
              })
            )

          return dispatcher(
            flows.forgot.form.email.state.update({
              state: "GOOD",
            })
          )
        },
      },
    },
    submit: {
      execute: (): ThunkAction<any, RootState, any, any> => async (
        dispatcher,
        getState
      ) => {
        dispatcher(flows.forgot.process.fetching())
        await dispatcher(flows.forgot.form.email.checkIfAlreadyUsed())

        const { auth, di } = getState()

        if (auth.flows.forgot.form.email.state.code !== "GOOD") {
          dispatcher(flows.forgot.process.fetchEnd())
          return false
        }

        const response = await di.AuthRepository.sendResetPasswordCode(
          auth.flows.forgot.form.email.value
        )

        dispatcher(flows.forgot.process.fetchEnd())

        if (response.succeed) {
          dispatcher(flows.forgot.process.succeed.update({ state: true }))
        } else {
          dispatcher(flows.forgot.process.error.set({ error: response.error }))
        }
      },
    },
  },
  // ANCHOR Forgot Validation
  forgotValidation: {
    steps: {
      next: (): types.AuthActionTypes => ({
        type: types.FlowsForgotValidationStepsNextType,
      }),
      previous: (): types.AuthActionTypes => ({
        type: types.FlowsForgotValidationStepsPreviousType,
      }),
    },
    process: {
      succeed: {
        update: (
          payload: types.FlowsForgotValidationProcessSuccessUpdateAction["payload"]
        ) => ({
          type: types.FlowsForgotValidationProcessSuccessUpdateType,
          payload,
        }),
      },
      error: {
        set: (
          payload: types.FlowsForgotValidationProcessSetErrorAction["payload"]
        ) => ({
          type: types.FlowsForgotValidationProcessSetErrorType,
          payload,
        }),
      },
      fetching: () => ({
        type: types.FlowsForgotValidationProcessFetchingType,
      }),
      fetchEnd: () => ({
        type: types.FlowsForgotValidationProcessFetchEndType,
      }),
    },
    form: {
      password: {
        update: (
          payload: types.FlowsForgotValidationFormPasswordUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsForgotValidationFormPasswordUpdateType,
          payload,
        }),
        state: {
          update: (
            payload: types.FlowsForgotValidationFormPasswordUpdateStateAction["payload"]
          ) => ({
            type: types.FlowsForgotValidationFormPasswordUpdateStateType,
            payload,
          }),
        },
        focus: {
          update: (
            payload: types.FlowsForgotValidationFormPasswordUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsForgotValidationFormPasswordUpdateFocusType,
            payload,
          }),
        },
        check: (): types.AuthActionTypes => ({
          type: types.FlowsForgotValidationFormPasswordCheckType,
        }),
        next: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          dispatcher(flows.signup.form.password.check())

          const { auth } = getState()

          if (auth.flows.signup.form.password.state.code === "GOOD") {
            dispatcher(flows.signup.steps.next())
          }
        },
        hidden: {
          toggle: (): types.AuthActionTypes => ({
            type: types.FlowsForgotValidationFormPasswordHiddenToggleType,
          }),
        },
      },
    },
    submit: {
      execute: (code: string): ThunkAction<any, RootState, any, any> => async (
        dispatcher,
        getState
      ) => {
        dispatcher(flows.forgotValidation.process.fetching())
        dispatcher(flows.forgotValidation.form.password.check())

        const { auth, di } = getState()

        if (auth.flows.forgotValidation.form.password.state.code !== "GOOD") {
          dispatcher(flows.forgotValidation.process.fetchEnd())
          return false
        }

        const response = await di.AuthRepository.checkResetPasswordCode(code)

        if (response.succeed) {
          await di.AuthRepository.updatePassword({
            email: response.email,
            password: auth.flows.forgotValidation.form.password.value,
            code,
          })

          dispatcher(flows.forgotValidation.process.fetchEnd())
          dispatcher(
            flows.forgotValidation.process.succeed.update({ state: true })
          )
        } else {
          dispatcher(flows.forgotValidation.process.fetchEnd())
          dispatcher(
            flows.forgotValidation.process.error.set({
              error: response.error,
            })
          )
        }
      },
    },
  },
  // ANCHOR Signup
  signup: {
    steps: {
      next: (): types.AuthActionTypes => ({
        type: types.FlowsSignupStepsNextType,
      }),
      previous: (): types.AuthActionTypes => ({
        type: types.FlowsSignupStepsPreviousType,
      }),
      fetchNext: (): ThunkAction<any, RootState, any, any> => async (
        dispatcher,
        getState
      ) => {
        const { auth } = getState()
        const current = auth.flows.signup.steps.current

        if (current === 0) return dispatcher(flows.signup.form.email.next())
        if (current === 1) return dispatcher(flows.signup.form.password.next())
        if (current === 2) return dispatcher(flows.signup.form.username.next())
        if (current === 3) return dispatcher(flows.signup.submit.execute())
      },
    },
    process: {
      fetching: () => ({ type: types.FlowsSignupProcessFetchingType }),
      fetchEnd: () => ({ type: types.FlowsSignupProcessFetchEndType }),
      errors: {
        set: (payload: types.FlowsSignupProcessErrorsSetAction["payload"]) => ({
          type: types.FlowsSignupProcessErrorsSetType,
          payload,
        }),
      },
    },
    form: {
      avatar: {
        update: (
          payload: types.FlowsSignupFormAvatarUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSignupFormAvatarUpdateType,
          payload,
        }),
      },
      email: {
        state: {
          update: (
            payload: types.FlowsSignupFormEmailUpdateStateAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSignupFormEmailUpdateStateType,
            payload,
          }),
        },
        focus: {
          update: (
            payload: types.FlowsSignupFormEmailUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSignupFormEmailUpdateFocusType,
            payload,
          }),
        },
        update: (
          payload: types.FlowsSignupFormEmailUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSignupFormEmailUpdateType,
          payload,
        }),
        check: (): types.AuthActionTypes => ({
          type: types.FlowsSignupFormEmailCheckType,
        }),
        checkIfAlreadyUsed: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          const { auth, di } = getState()
          const email = auth.flows.signup.form.email.value

          const { exists } = await di.AuthRepository.checkIfUserExistsByEmail(
            email
          )

          if (exists)
            return dispatcher(
              flows.signup.form.email.state.update({
                state: "ALREADY_USED",
              })
            )
        },
        next: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          dispatcher(flows.signup.process.fetching())
          dispatcher(flows.signup.form.email.check())
          await dispatcher(flows.signup.form.email.checkIfAlreadyUsed())
          dispatcher(flows.signup.process.fetchEnd())

          const { auth } = getState()

          if (auth.flows.signup.form.email.state.code === "GOOD") {
            dispatcher(flows.signup.steps.next())
          }
        },
      },
      username: {
        focus: {
          update: (
            payload: types.FlowsSignupFormUsernameUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSignupFormUsernameUpdateFocusType,
            payload,
          }),
        },
        update: (
          payload: types.FlowsSignupFormUsernameUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSignupFormUsernameUpdateType,
          payload,
        }),
        check: (): types.AuthActionTypes => ({
          type: types.FlowsSignupFormUsernameCheckType,
        }),
        checkIfAlreadyUsed: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          const { auth, di } = getState()
          const username = auth.flows.signup.form.username.value

          const {
            exists,
          } = await di.AuthRepository.checkIfUserExistsByUsername(username)

          if (exists)
            return dispatcher(
              flows.signup.form.username.state.update({
                state: "ALREADY_USED",
              })
            )
        },
        next: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          dispatcher(flows.signup.process.fetching())

          dispatcher(flows.signup.form.username.check())
          await dispatcher(flows.signup.form.username.checkIfAlreadyUsed())

          dispatcher(flows.signup.process.fetchEnd())

          const { auth } = getState()

          if (auth.flows.signup.form.username.state.code === "GOOD") {
            dispatcher(flows.signup.steps.next())
          }
        },
        state: {
          update: (
            payload: types.FlowsSignupFormUsernameUpdateStateAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSignupFormUsernameUpdateStateType,
            payload,
          }),
        },
      },
      password: {
        update: (
          payload: types.FlowsSignupFormPasswordUpdateAction["payload"]
        ): types.AuthActionTypes => ({
          type: types.FlowsSignupFormPasswordUpdateType,
          payload,
        }),
        focus: {
          update: (
            payload: types.FlowsSignupFormPasswordUpdateFocusAction["payload"]
          ): types.AuthActionTypes => ({
            type: types.FlowsSignupFormPasswordUpdateFocusType,
            payload,
          }),
        },
        check: (): types.AuthActionTypes => ({
          type: types.FlowsSignupFormPasswordCheckType,
        }),
        next: (): ThunkAction<any, RootState, any, any> => async (
          dispatcher,
          getState
        ) => {
          dispatcher(flows.signup.form.password.check())

          const { auth } = getState()

          if (auth.flows.signup.form.password.state.code === "GOOD") {
            dispatcher(flows.signup.steps.next())
          }
        },
        hidden: {
          toggle: (): types.AuthActionTypes => ({
            type: types.FlowsSignupFormPasswordHiddenToggleType,
          }),
        },
      },
    },
    submit: {
      execute: (): ThunkAction<any, RootState, any, any> => async (
        dispatcher,
        getState
      ) => {
        const { auth, di } = getState()

        dispatcher(flows.signup.process.fetching())

        const response = await di.AuthRepository.register({
          email: auth.flows.signup.form.email.value,
          password: auth.flows.signup.form.password.value,
          avatar: auth.flows.signup.form.avatar.value as string,
          username: auth.flows.signup.form.username.value,
        })

        dispatcher(flows.signup.process.fetchEnd())

        if (response.authenticated) {
          dispatcher(authenticate({ user: response.user }))
          dispatcher(flows.reset())
          di.LocationService.navigate("/app/")
        } else {
          // dispatcher(
          //   flows.signup.process.errors.set({ errors: response.errors })
          // )
        }
      },
    },
  },
}
