import { ThunkAction } from "redux-thunk"
import { RootState } from "../store"
import * as types from "./types"

export const init = (): types.SoundsActionTypes => ({
  type: types.Init,
})

export const play = (
  name: keyof types.SoundsType
): ThunkAction<void, RootState, any, any> => async (dispatcher, getState) => {
  const { sounds } = getState()

  if (!sounds.audios) return false
  
  const audio = sounds.audios[name].cloneNode() as HTMLAudioElement

  audio.volume = 0.4
  await audio.play()
  audio.remove()
}
