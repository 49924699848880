import { IEmailRepository } from "../interfaces/IEmailRepository"
import { Firebase } from "../services/firebase"
import { FirebaseUtils } from "../utils/FirebaseUtils"

export class FirebaseEmailRepository
  extends FirebaseUtils
  implements IEmailRepository {
  constructor(private firebase: Firebase) {
    super()
  }

  async store(email: string): Promise<void> {
    await this.firebase
      .database()
      .collection("email")
      .doc(email)
      .set({ email, date: new Date() })
  }
}
