export default {
  "choose.mode.title": "Selectionner un mode de jeu",
  "choose.mode.challenge": "Défis quotidiens",
  "choose.mode.grids": "Mode Classique",
  "choose.mode.stats": "Statistiques",

  "choose.tutorial": "Apprendre à jouer",
  "choose.buttons.next": "Suivant",
  "choose.buttons.previous": "Précédent",

  "modal/locked/title": "Cette fonctionnalité arrivera prochainement",
  "modal/locked/submit": "Envoyer",
  "modal/locked/description":
    "Envie d'être informé lorsque la fonctionnalité sera en ligne ? Suivez-nous sur les réseaux sociaux ou remplissez votre adresse email, nous vous tiendrons informé.",

  "index.try.title": "Envie d'essayer ?",
  "index.try.description":
    "Jouez autant que vous le souhaitez, c'est gratuit !",
  "index.try.play": "Jouer",

  "challenges.choose.title": "La difficulté du défi",

  "footer.rights": "Tous droits réservés.",

  "index.header.play": "Jouer",

  "index.hero.title.first": `Futoshiki`,
  "index.hero.title.last": `le nouveau Sudoku`,
  "index.hero.subtitle": "Nouveautés",
  "index.hero.description": `
    Le Futoshiki est la version moderne du Sudoku, venez découvrir
    ce nouveau puzzle game à travers +200 grilles en allant du
    niveau facile à très difficile.
  `,

  "index.stats.title": "Futoshiki en plusieurs chiffres",
  "index.stats.description":
    "Détendez vous et relevez les défis qu'on offre avec une infinité de grilles de Futoshiki.",

  "index.stats.grid.first.title": `Défis à relever`,
  "index.stats.grid.first.value": `+200`,

  "index.stats.grid.middle.title": `Grilles à découvrir`,
  "index.stats.grid.middle.value": `500K`,

  "index.stats.grid.last.title": `Zen attitude`,
  "index.stats.grid.last.value": `100%`,

  "index.content.title": "C'est quoi les règles et principe du futoshiki ?",
  "index.content.description":
    "Un sudoku différent mélangeant logique et mémorisation",

  "index.content.content.left": `
  <p>
    Dans le sudoku, chaque ligne, chaque colonne et chaque
    blocs doivent comporter uniquement des chiffres
    différents. Dans le futoshiki, c'est la même chose, à
    l'exception qu'il n'y a pas de bloc, mais seulement des
    lignes et des colonnes.
  </p>
  <p>
    Enfin, ce qu'il y a de nouveau dans le futoshiki c'est
    l'apparation de symboles en forme de flèche. La flèche
    indique que le chiffre de la case adjacente est une plus
    petit ou plus grand que l'autre case, tout simplement.
  </p>
`,
  "index.content.content.right": `
  <p>
    Pour résumer, le futoshiki est une version alternative du
    sudoku, qui a été créé en 2006. Nous pensons que le
    futoshiki mérite de gagner en popularité, c'est pour cela
    que nous avons crée ce site pour vous permettre de
    découvrir et de prendre du plaisir en jouant au futoshiki.
  </p>
  `,

  "levels.very.easy": `Très facile`,
  "levels.easy": `Facile`,
  "levels.medium": `Normal`,
  "levels.hard": `Difficile`,
  "levels.very.hard": `Très difficile`,
  "levels.pro": `Professionnel`,
  "levels.end": `Fin`,

  "play.success.title": "C'est un succès !",
  "play.success.description":
    "Vous avez terminé cette grille, félicitations ! Il vous reste encore pleins d'autres grilles à découvrir !",
  "play.success.submit": "Continuer",

  "play.controls.notes": "Annotations",
  "play.controls.undo": "Précédent",
  "play.controls.erase": "Supprimer",

  "tutorial.success.title": "Vous avez terminé le tutoriel !",
  "tutorial.success.description":
    "Vous êtes dorénavant prêt à relever tous les défis que vous réserve le futoshiki ! Appuyez sur continuer pour choisir en relever un !",
  "tutorial.success.submit": "Continuer",

  "tutorial.steps.1": "Oh ! Un nouvel élève !",
  "tutorial.steps.2": "Mmh.. Je me présente, je suis le maître Futoshiki !",
  "tutorial.steps.3":
    "Je m'apprête à t'enseigner les bases de ce noble casse-tête…",
  "tutorial.steps.4": "Es-tu prêt ? Allons-y !",
  "tutorial.steps.5":
    "Tout d'abord, l'objectif est de remplir chaque case d'un chiffre",
  "tutorial.steps.6":
    "Par contre, le chiffre ne doit pas figurer plus d'une fois par ligne et colonne !",
  "tutorial.steps.7": "Rectifions cela…",
  "tutorial.steps.8": "Voilà c'est beaucoup mieux !",
  "tutorial.steps.9":
    "Maintenant, voyons à quoi servent ces flèches qu'on voit un peu partout.",
  "tutorial.steps.10":
    "La flèche de droite montre que le chiffre de la case suivante est inférieur.",
  "tutorial.steps.11":
    "Du coup, le chiffre en dessous est donc aussi inférieur",
  "tutorial.steps.12": "Et ainsi de suite…",
  "tutorial.steps.13":
    "Grâce à elles, on peut donc deviner quels sont les bon chiffre",
  "tutorial.steps.14": "La seule suite possible est donc le 4,3,2 et 1",
  "tutorial.steps.15":
    "Voilà c'est tout ce qu'il y avait à savoir à propos du futoshiki !",
  "tutorial.steps.16":
    "Je donnerai d'autres cours sur des stratégies plus avancées plus tard",
  "tutorial.steps.17": "En attendant, je te souhaite de bonne partie !",
  "tutorial.steps.18": " ",

  "auth.actions.reset-password.errors.invalid-action-code":
    "Le code d'action est invalide.",
  "auth.actions.reset-password.errors.expired-action-code":
    "Le code de réinitialisation a expiré, veuillez recommencer en cliquant ci-dessous.",
  "auth.actions.reset-password.errors.user-disabled":
    "L'utilisateur n'existe plus.",
  "auth.actions.reset-password.errors.user-not-found":
    "L'utilisateur n'a pas été trouvé.",
}
