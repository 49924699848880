import { createStore, applyMiddleware, combineReducers } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { actions } from "./actions"

import { authReducer } from "./auth/reducers"
import { playReducer } from "./play/reducers"
import { snackReducer } from "./snack/reducers"
import { menuReducer } from "./menu/reducers"
import { modalReducer } from "./modal/reducers"
import { tutorialReducer } from "./tutorial/reducers"
import { demoReducer } from "./demo/reducers"
import { soundsReducer } from "./sounds/reducers"
import { diReducer } from "./di/reducers"
import { DiInjectable } from "./di/types"

const enhancer = composeWithDevTools(applyMiddleware(thunk))

export const reducers = combineReducers({
  auth: authReducer,
  play: playReducer,
  tutorial: tutorialReducer,
  sounds: soundsReducer,
  snack: snackReducer,
  menu: menuReducer,
  modal: modalReducer,
  di: diReducer,
  demo: demoReducer,
})

export type RootState = ReturnType<typeof reducers>

export const init = (initialState = {}, di: DiInjectable[]) => {
  const store = createStore(reducers, initialState, enhancer)

  di.forEach(({ key, value }) => {
    store.dispatch(
      //@ts-ignore
      actions.di.register({
        key,
        value,
      })
    )
  })

  return { store }
}
