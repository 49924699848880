import * as types from "./types"

interface DemoState {
  steps: Array<{ text: string; actions?: Array<Array<string>> }>
  current: number
  end: boolean
}

const initialState: DemoState = {
  steps: [
    { text: "" },
    {
      text: "",
      actions: [
        ["select", "0:0"],
        ["change", "2", "500"],
        ["select", "0:1"],
        ["change", "1", "500"],
        ["select", "0:2"],
        ["change", "4", "500"],
        ["select", "0:3"],
        ["change", "3", "500"],
        ["select", "0:4"],
        ["change", "5", "500"],

        ["select", "1:0"],
        ["change", "1", "500"],
        ["select", "1:1"],
        ["change", "4", "500"],
        ["select", "1:2"],
        ["change", "5", "500"],
        ["select", "1:3"],
        ["change", "2", "500"],
        ["select", "1:4"],
        ["change", "3", "500"],

        ["select", "2:0"],
        ["change", "5", "500"],
        ["select", "2:1"],
        ["change", "3", "500"],
        ["select", "2:2"],
        ["change", "2", "500"],
        ["select", "2:3"],
        ["change", "4", "500"],
        ["select", "2:4"],
        ["change", "1", "500"],

        ["select", "3:0"],
        ["change", "3", "500"],
        ["select", "3:1"],
        ["change", "2", "500"],
        ["select", "3:2"],
        ["change", "1", "500"],
        ["select", "3:3"],
        ["change", "5", "500"],
        ["select", "3:4"],
        ["change", "4", "500"],

        ["select", "4:0"],
        ["change", "4", "500"],
        ["select", "4:1"],
        ["change", "5", "500"],
        ["select", "4:2"],
        ["change", "3", "500"],
        ["select", "4:3"],
        ["change", "1", "500"],
        ["select", "4:4"],
        ["change", "2", "500"],
      ],
    },
  ],
  current: 0,
  end: false,
}

export function demoReducer(
  state = initialState,
  action: types.DemoActionTypes
): DemoState {
  if (action.type === types.Next) {
    const current = state.current + 1

    return {
      ...state,
      current,
      end: current >= state.steps.length - 1,
    }
  }

  if (action.type === types.Reset) {
    return {
      ...initialState,
    }
  }

  return state
}
