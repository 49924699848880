import * as types from "./types"
import { ThunkDispatch, ThunkAction } from "redux-thunk"
import { RootState } from "../store"
import delay from "delay"

export const close = (): types.ModalActionTypes => ({
  type: types.close,
})

export const storeOpen = (): types.ModalActionTypes => ({
  type: types.open,
})

export const unlockClose = (): types.ModalActionTypes => ({
  type: types.unlockClose,
})

export const unlock = {
  submit: (email: string): ThunkAction<any, RootState, any, any> => async (
    dispatch,
    getState
  ) => {
    const { di } = getState()
    const repository = di.EmailRepository
    await repository.store(email)
    dispatch(unlockClose())
  },
}

export const unlockOpen = (): types.ModalActionTypes => ({
  type: types.unlockOpen,
})

export const openCta = (
  payload: types.openCtaAction["payload"]
): types.ModalActionTypes => ({
  type: types.openCta,
  payload,
})

export const closeCta = (): types.ModalActionTypes => ({
  type: types.closeCta,
})

export const open = (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(storeOpen())

  setTimeout(() => {
    dispatch(close())
  }, 3000)
}

export const ctaFetching = () => ({
  type: types.ctaFetching,
})

export const ctaFetchEnd = () => ({
  type: types.ctaFetchEnd,
})

export const fetchSubmit = (): ThunkAction<any, RootState, any, any> => async (
  dispatch,
  getState
) => {
  const { modal } = getState()

  dispatch(ctaFetching())

  await delay(500)
  if (modal.cta.onSubmit) await modal.cta.onSubmit()

  dispatch(ctaFetchEnd())
  dispatch(closeCta())
}
