import { ThunkAction } from "redux-thunk"
import delay from "delay"
import { RootState } from "../store"
import * as types from "./types"

export const selectGame = (
  payload: types.SelectGameAction["payload"]
): types.PlayActionTypes => ({
  type: types.SelectGame,
  payload,
})

export const selectCel = (
  payload: types.SelectCelAction["payload"]
): types.PlayActionTypes => ({
  type: types.SelectCel,
  payload,
})

export const nextChoose = (): types.PlayActionTypes => ({
  type: types.NextChoose,
})

export const previousChoose = (): types.PlayActionTypes => ({
  type: types.PreviousChoose,
})

export const reset = (): types.PlayActionTypes => ({
  type: types.Reset,
})

export const undo = (): types.PlayActionTypes => ({
  type: types.Undo,
})

export const changeDraft = (
  payload: types.ChangeDraftAction["payload"]
): types.PlayActionTypes => ({
  type: types.ChangeDraft,
  payload,
})

export const toggleDraftMode = (): types.PlayActionTypes => ({
  type: types.ToggleDraftMode,
})

export const changeCel = (
  payload: types.ChangeCelAction["payload"]
): types.PlayActionTypes => ({
  type: types.ChangeCel,
  payload,
})

export const fetchChangeCel = (
  payload: types.ChangeCelAction["payload"]
): ThunkAction<any, RootState, any, any> => async (
  dispatcher,
  getState,
  extraArgs
) => {
  dispatcher(changeCel(payload))

  const { play, di } = getState()

  if (play.win) {
    const storage = Array.from(play.history.values())

    localStorage.setItem("history", JSON.stringify(storage))

    di.AnalyticsService.send("play/finish", { game: play.actual?.id })
  }
}

export const storeHistory = (
  payload: types.StoreHistoryAction["payload"]
): types.PlayActionTypes => ({
  type: types.StoreHistory,
  payload,
})

export const storeGames = (
  payload: types.StoreGamesAction["payload"]
): types.PlayActionTypes => ({
  type: types.StoreGames,
  payload,
})

export const storeChallenges = (
  payload: types.StoreChallengesAction["payload"]
): types.PlayActionTypes => ({
  type: types.StoreChallenges,
  payload,
})

export const fetching = (): types.PlayActionTypes => ({
  type: types.Fetching,
})

export const fetchEnd = (): types.PlayActionTypes => ({
  type: types.FetchEnd,
})

export const fetchHistory = (): ThunkAction<any, RootState, any, any> => (
  dispatcher,
  getState
) => {
  const storage = localStorage.getItem("history")
  const history = storage ? JSON.parse(storage) : []

  return dispatcher(storeHistory({ history }))
}

export const load = (): ThunkAction<any, RootState, any, any> => async (
  dispatcher,
  getState
) => {
  const { di } = getState()
  const games = await di.GridsRepository.findClassicalGames()

  dispatcher(storeGames({ games }))
}

export const fetchSelectGame = (
  payload: types.SelectGameAction["payload"]
): ThunkAction<any, RootState, any, any> => async (
  dispatcher,
  getState,
  extraArgs
) => {
  const { play, di } = getState()
  const { games } = play

  dispatcher(fetching())

  if (games.size) {
    dispatcher(selectGame(payload))

    di.AnalyticsService.send("play", { game: play.actual?.id })

    dispatcher(fetchEnd())
  } else {
    await delay(100)
    return fetchSelectGame(payload)(dispatcher, getState, extraArgs)
  }
}
