import { IAnalyticsService } from "../../interfaces/IAnalyticsService"
import { IAuthRepository } from "../../interfaces/IAuthRepository"
import { IEmailRepository } from "../../interfaces/IEmailRepository"
import { IGridsRepository } from "../../interfaces/IGridsRepository"
import { ILocationService } from "../../interfaces/ILocationService"


export type DiInjectable =
  | { key: "AuthRepository"; value: IAuthRepository }
  | { key: "GridsRepository"; value: IGridsRepository }
  | { key: "LocationService"; value: ILocationService }
  | { key: "AnalyticsService"; value: IAnalyticsService }
  | { key: "EmailRepository"; value: IEmailRepository }

export const register = "REDUX_DI_REGISTER"
export interface registerAction {
  type: typeof register
  payload: DiInjectable
}

export type DiActionTypes = registerAction
